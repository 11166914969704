import React from 'react';
import styled from 'styled-components';
import underConstructionImg from '../../assets/images/under_construction.jpeg';

const Container = styled.div`
    padding: 20px;
    img {
        width: 100%;
    }
`;

const UnderConstruction = () => (
    <Container>
        <img src={underConstructionImg} alt="under construction" />
    </Container>
);

export default UnderConstruction;
