import { REDUX } from '../../constants';
// actions types
export const { GET_USER_INFO } = REDUX.USER;
export const { GET_USER_INFO_NO_WARNING } = REDUX.USER;
export const { GET_USER_FAILED } = REDUX.USER;
export const { GET_USER_FAILED_NO_WARNING } = REDUX.USER;
export const { UPDATE_USER_INFO } = REDUX.USER;
export const { CLEAR_USER_INFO } = REDUX.USER;
export const { CHANGE_USER_INFO } = REDUX.USER;
export const { DELETE_USER } = REDUX.USER;
export const { DELETE_USER_SUCCESS } = REDUX.USER;
export const { DELETE_USER_FAILED } = REDUX.USER;
export const { SET_LOADING } = REDUX.USER;
export const { UPDATE_EMAIL } = REDUX.APP;
export const { REMOVE_PROFILE_PICTURE } = REDUX.APP;
export const { REMOVE_WEBSITE_LOGO } = REDUX.APP;
export const { GET_USER_WEBSITE } = REDUX.APP;
export const { PUBLISH_USER_WEBSITE } = REDUX.APP;
export const { UNPUBLISH_USER_WEBSITE } = REDUX.APP;
export const { UPLOAD_FINETUNE_CSV } = REDUX.APP;

export function getUserInfo() {
    return { type: GET_USER_INFO };
}
export function getUserInfoNoWarning() {
    return { type: GET_USER_INFO_NO_WARNING };
}
export function getUserFailed() {
    return { type: GET_USER_FAILED };
}
export function getUserFailedNoWarning() {
    return { type: GET_USER_FAILED_NO_WARNING };
}
export function updateUserInfo(value) {
    return { type: UPDATE_USER_INFO, value };
}

export function clearUserInfo() {
    return { type: CLEAR_USER_INFO };
}

export function changeUserInfo(payload) {
    return { type: CHANGE_USER_INFO, payload };
}

export function updateEmail(payload) {
    return { type: UPDATE_EMAIL, payload };
}

export function deleteUser() {
    return { type: DELETE_USER };
}

export function deleteUserSuccess() {
    return { type: DELETE_USER_SUCCESS };
}

export function deleteUserFailed(payload) {
    return { type: DELETE_USER_FAILED, payload };
}

export function setLoading(isLoading) {
    return { type: SET_LOADING, payload: isLoading };
}

export function removeProfilePicture(successCb, failCb) {
    return { type: REMOVE_PROFILE_PICTURE, successCb, failCb };
}

export function removewebsiteLogo(payload) {
    return { type: REMOVE_WEBSITE_LOGO, payload };
}

export function checkuserWebsite(successCb, failCb) {
    return { type: GET_USER_WEBSITE, successCb, failCb };
}

export function publishWebsite(payload) {
    return { type: PUBLISH_USER_WEBSITE, payload };
}

export function unpublishWebsite() {
    return { type: UNPUBLISH_USER_WEBSITE };
}

export function uploadFinetuneCsv(payload, successCb, failCb) {
    return { type: UPLOAD_FINETUNE_CSV, payload, successCb, failCb };
}

export const actions = {
    getUserInfo,
    getUserInfoNoWarning,
    updateUserInfo,
    clearUserInfo,
    getUserFailed,
    changeUserInfo,
    updateEmail,
    removeProfilePicture,
    checkuserWebsite,
    removewebsiteLogo,
    publishWebsite,
    unpublishWebsite,
    uploadFinetuneCsv,
};
