import { lazy } from 'react';
import UnderConstruction from '../views/underConstruction/UnderConstruction';

export const routes = [
    {
        path: [
            '/',
            '/login',
            '/signup',
            '/emailConfirmed',
            '/terms-of-use',
            '/privacy-policy',
            '/aboutus',
        ],
        exact: true,
        component: lazy(() => import('../views/Landing/Landing')),
        routes: [
            {
                path: '/',
                exact: true,
                component: lazy(() => import('../views/Landing/Home')),
            },
            {
                path: '/login',
                component: lazy(() => import('../views/Landing/Login')),
            },
            {
                path: '/signup',
                component: lazy(() => import('../views/Landing/Login')),
            },
            {
                path: '/emailConfirmed',
                component: lazy(() => import('../views/Landing/EmailUpdateSuccess')),
            },
            {
                path: '/terms-of-use',
                component: lazy(() => import('../views/Landing/TermsOfUse')),
            },
            {
                path: '/privacy-policy',
                component: lazy(() => import('../views/Landing/PrivacyPolicy')),
            },
            {
                path: '/aboutus',
                component: lazy(() => import('../views/Landing/AboutUs')),
            },
        ],
    },
    {
        path: ['/admin/goduser'],
        component: lazy(() => import('../views/goduser/Goduser')),
    },
    {
        path: '/dashboard',
        component: lazy(() => import('../views/dashboard/dashboardLayout')),
        routes: [
            {
                path: '/dashboard/profile',
                component: lazy(() => import('../views/dashboard/setup/Setup')),
            },
            {
                path: '/dashboard/people',
                component: lazy(() => import('../views/dashboard/people/People')),
            },
            {
                path: '/dashboard/literature',
                component: lazy(() => import('../views/dashboard/literature/Literature')),
            },
            {
                path: '/dashboard/edit-scholarly-work/:scholarlyType?',
                component: lazy(() => import('../views/dashboard/scholarlyWork/AddScholarlyWork')),
            },
            {
                path: '/dashboard/library',
                component: lazy(() => import('../views/dashboard/library/Library')),
            },
            {
                path: '/dashboard/add-scholarly-work/:scholarlyType?',
                component: lazy(() => import('../views/dashboard/scholarlyWork/AddScholarlyWork')),
            },
            {
                path: '/dashboard/cv',
                component: lazy(() => import('../views/dashboard/cv/Cv')),
            },
            {
                path: '/dashboard/general-description/:category',
                component: lazy(() =>
                    import('../views/dashboard/generalDescription/GeneralDescription')
                ),
            },
            {
                path: '/dashboard/help-support',
                component: lazy(() => import('../views/dashboard/support/Support')),
            },
            {
                path: '/dashboard/personal-website',
                component: lazy(() => import('../views/dashboard/personalWebsite/PersonalWebsite')),
            },
            {
                path: '/dashboard/ai-assistant',
                component: lazy(() =>
                    import('../views/dashboard/aiWrittingAssistant/AiWritingAssistant')
                ),
            },
            {
                path: '/dashboard/*',
                component: UnderConstruction,
            },
        ],
    },
    {
        path: '/setup',
        component: lazy(() => import('../views/setup/PageLayout')),
        routes: [
            {
                path: '/setup',
                component: lazy(() => import('../views/dashboard/setup/Setup')),
            },
        ],
    },
    {
        path: ['/prices', '/subscribe', '/checkout'],
        component: lazy(() => import('../views/subscription/SubscriptionLayout')),
        routes: [
            {
                path: '/prices',
                exact: true,
                component: lazy(() => import('../views/subscription/Prices/Prices')),
            },
            {
                path: '/checkout',
                component: lazy(() => import('../views/subscription/Checkout/Layout')),
                routes: [
                    {
                        path: '/checkout/review-cart',
                        component: lazy(() => import('../views/subscription/Checkout/ReviewCart')),
                    },
                    {
                        path: '/checkout/payment',
                        component: lazy(() => import('../views/subscription/Checkout/ReviewCart')),
                    },
                    {
                        path: '/checkout/confirmation',
                        component: lazy(() =>
                            import('../views/subscription/Checkout/Confirmation')
                        ),
                    },
                ],
            },
        ],
    },
];

export const personalWebsiteRoutes = [
    {
        path: '/',
        component: lazy(() => import('../views/personalWebsite/Layout')),
        routes: [
            {
                path: '/',
                exact: true,
                component: lazy(() => import('../views/personalWebsite/Home/Home')),
            },
            {
                path: '/contact',
                exact: true,
                component: lazy(() => import('../components/personalWebsiteComponents/Contact')),
            },
            {
                path: '/contact-sidebar',
                exact: true,
                component: lazy(() =>
                    import('../components/personalWebsiteComponents/ContactSideBar')
                ),
            },
            {
                path: '/research',
                component: lazy(() => import('../views/personalWebsite/Research/Research')),
            },
            {
                path: '/otherworks',
                component: lazy(() => import('../views/personalWebsite/OtherWorks/OtherWorks')),
            },
            {
                path: '/teaching',
                component: lazy(() => import('../views/personalWebsite/Teaching/Teaching')),
            },
            {
                path: '/cv',
                component: lazy(() => import('../views/personalWebsite/CV/CV')),
            },
        ],
    },
];
