/* eslint-disable no-irregular-whitespace */
const login = {
    signin: 'Sign In',
    signinOrCreateAccount: 'Sign In or Create Account',
    loginText:
        'The most efficient way to build online presence, keep up with the latest in research, and engage your research community.',
    privacyText: `<p>By clicking “Sign in” or "Create free account" above, you acknowledge that you have read and understood, and agree to Cloquia's <a href="/terms-of-use">Terms & Conditions</a> and <a href="/privacy-policy">Privacy Policy</a>.</p>`,
    sentMagicLink: `We just sent you a magic link to log in or sign up.<br/>Please check your inbox`,
    checkSpam: `Don't forget to check your spam inbox if you can't find the log in email that we sent you.`,
    login: 'Login',
    emailYou: "We'll email you a magic code for a password free sign-in.",
    email: 'Email',
    emailAddress: 'Email Address',
    emailInputPlaceholder: 'Enter your .edu email address...',
    continueWithEmail: 'Continue with Email',
    ByClicking:
        'By clicking "Sign In" above, you acknowledge that you have read and understood and agree to ',
    terms: "Cloquia's Terms & Privacy Policy",
    confirmEmail: 'Confirm your email',
    emailedLink: 'We emailed a magic link to',
    clickLink: 'Click the link to login or sign up.',
    sentLoginCode: 'We just sent you a temporary login code.',
    checkInbox: 'Please check your inbox.',
    loginCode: 'Login code',
    pasteLoginCode: 'Paste login code',
    continueWithLoginCode: 'Continue with login code',
    linkBroken:
        'Link is broken -- please request a new magic link or open the link from another email client',
    sendAnother: 'Send another magic link to',
    send: 'Send',
    sent: 'We just sent you an e-mail.',
    checkSpamInbox:
        'Please also check your spam inbox, if you cannot find the login email that we sent you.',
};
const privacyPolicy = {
    privacyPolicy: 'Privacy Policy',
    introduction: 'Introduction',
    definitions: 'Definitions',
    information: 'Information Collection and Use',
    typesOfData: 'Types of Data Collected',
    useOfData: 'Use of Data',
    retentionOfData: 'Retention of Data',
    transferOfData: 'Transfer of Data',
    disclosureOfData: 'Disclosure of Data',
    securityOfData: 'Security of Data',
    gdpr: 'Your Data Protection Rights Under General Data Protection Regulation (GDPR)',
    calOPPA: 'CalOPPA',
    calOPPATitle:
        'Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)',
    serviceProviders: 'Service Providers',
    analytics: 'Analytics',
    cicdTools: 'CI/CD tools',
    payments: 'Payments',
    linkToOtherSites: 'Links to Other Sites',
    introductionContent: `
    <p>Welcome to <b>Cloquia, Inc.</b></p>
    <p>
        Cloquia, Inc. (“us”, “we”, or “our”)
        operates https://cloquia.com (hereinafter referred to as
        “<b>Service</b>”).
    </p>
    <p>
        Our Privacy Policy governs your visit
        to https://cloquia.com, and explains how we collect,
        safeguard and disclose information that results from your
        use of our Service.
    </p>
    <p>
        We use your data to provide and improve Service. By using
        Service, you agree to the collection and use of information
        in accordance with this policy. Unless otherwise defined in
        this Privacy Policy, the terms used in this Privacy Policy
        have the same meanings as in our Terms and Conditions.
    </p>
    <p>
        Our Terms and Conditions (“<b>Terms</b>”) govern all use of our
        Service and together with the Privacy Policy constitutes
        your agreement with us (“<b>agreement</b>”).
    </p>
    `,
    definitionsContent: `
    <p><b>SERVICE</b> means the https://cloquia.com website operated by Cloquia, Inc.</p>
    <p><b>PERSONAL DATA</b> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
    <p><b>USAGE DATA</b> is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</p>
    <p><b>COOKIES</b> are small files stored on your device (computer or mobile device).</p>
    <p><b>DATA CONTROLLER</b> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
    <p><b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</p>
    <p><b>DATA SUBJECT</b> is any living individual who is the subject of Personal Data.</p>
    <p><b>THE USER</b> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>
    `,
    informationContent: `
    <h4>Personal Data</h4>
    <p>
    We collect several different types of information for various purposes to provide and improve our Service to you.
    </p>
    `,
    typesOfDataContent: `
        <h4>Personal Data</h4>
        <p>
            While using our Service, we may ask you to provide us
            with certain personally identifiable information that
            can be used to contact or identify you (“<b>Personal
            Data</b>”). Personally identifiable information may include,
            but is not limited to:
        </p>
        <ol class="list-style-lower-alpha"
        >
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Cookies and Usage Data</li>
        </ul>
        <p>
            We may use your Personal Data to contact you with
            newsletters, marketing or promotional materials and
            other information that may be of interest to you. You
            may opt out of receiving any, or all, of these
            communications from us by following the unsubscribe link
            or by emailing at support@cloquia.com.
        </p>

        <h4>Usage Data</h4>
        <p>
            We may also collect information that your browser sends
            whenever you visit our Service or when you access
            Service by or through a mobile device (“<b>Usage Data</b>”).
        </p>
        <p>
            This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address),
            browser type, browser version, the pages of our Service
            that you visit, the time and date of your visit, the
            time spent on those pages, unique device identifiers and
            other diagnostic data.
        </p>
        <p>
            When you access Service with a mobile device, this Usage
            Data may include information such as the type of mobile
            device you use, your mobile device unique ID, the IP
            address of your mobile device, your mobile operating
            system, the type of mobile Internet browser you use,
            unique device identifiers and other diagnostic data.
        </p>

        <h4>Tracking Cookies Data</h4>
        <p>
            We use cookies and similar tracking technologies to
            track the activity on our Service and we hold certain
            information.
        </p>
        <p>
            Cookies are files with a small amount of data which may
            include an anonymous unique identifier. Cookies are sent
            to your browser from a website and stored on your
            device. Other tracking technologies are also used such
            as beacons, tags and scripts to collect and track
            information and to improve and analyze our Service.
        </p>
        <p>
            You can instruct your browser to refuse all cookies or
            to indicate when a cookie is being sent. However, if you
            do not accept cookies, you may not be able to use some
            portions of our Service.
        </p>

        <p>Examples of Cookies we use:</p>
        <ol class="list-style-lower-alpha">
            <li>
                <b>Session Cookies:</b> We use Session Cookies
                to operate our Service.
            </li>
            <li>
                <b>Preference Cookies:</b> We use Preference
                Cookies to remember your preferences and various
                settings.
            </li>
            <li>
                <b>Security Cookies:</b> We use Security Cookies
                for security purposes.
            </li>
            <li>
                <b>Advertising Cookies:</b> Advertising Cookies
                are used to serve you with advertisements that may
                be relevant to you and your interests.
            </li>
        </ul>

        <h4>Other Data</h4>
        <p>
            While using our Service, we may also collect the
            following information: sex, age, date of birth, place of
            birth, passport details, citizenship, registration at
            place of residence and actual address, telephone number
            (work, mobile), details of documents on education,
            qualification, professional training, employment
            agreements, non-disclosure agreements, information on
            bonuses and compensation, information on marital status,
            family members, social security (or other taxpayer
            identification) number, office location and other data.
        </p>
    `,
    useOfDataContent: `
    <p>Cloquia, Inc. uses the collected data for various purposes:</p>
    <ol class="list-style-lower-alpha">
        <li>to provide and maintain our Service;</li>
        <li>to notify you about changes to our Service; </li>
        <li>to allow you to participate in interactive features of our Service when you choose to do so; </li>
        <li>to provide customer support; </li>
        <li>to gather analysis or valuable information so that we can improve our Service;</li>
        <li>to monitor the usage of our Service;</li>
        <li>to detect, prevent and address technical issues;</li>
        <li>to fulfill any other purpose for which you provide it;</li>
        <li>to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</li>
        <li>to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;</li>
        <li>to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</li>
        <li>in any other way we may describe when you provide the information;</li>
        <li>for any other purpose with your consent. </li>
    </ul>
    `,
    retentionOfDataContent: `
    <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
    <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
    `,
    transferOfDataContent: `
    <p>Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
    <p>If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.</p>
    <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
    <p>Cloquia, Inc. will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
    `,
    disclosureOfDataContent: `
    <p>We may disclose personal information that we collect, or you provide:</p>
    <ol class="list-style-lower-alpha">
        <li>
            <b>Disclosure for Law Enforcement.</b>
            <p>Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.</p>
        </li>
        <li>
            <b>Business Transaction.</b>
            <p>If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</p>
        </li>
        <li>
            <b>Other cases. We may disclose your information also:</b>
            <ol class="list-style-roman">
                <li>to contractors, service providers, and other third parties we use to support our business;
                </li>
                <li>to fulfill the purpose for which you provide it;</li>
                <li>for any other purpose disclosed by us when you provide the information;</li>
                <li>with your consent in any other cases;</li>
                <li>if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</li>
            </ol>
        </li>
    </ol>
    `,
    securityOfDataContent: `
        <p>
        The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
        </p>
    `,
    gdprContent: `
        <p>If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR. – See more at https://eur-lex.europa.eu/eli/reg/2016/679/oj </p>
        <p>We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
        <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at support@cloquia.com. </p>
        <p>In certain circumstances, you have the following data protection rights:</p>
        <ol class="list-style-lower-alpha">
            <li>the right to access, update or to delete the information we have on you;</li>
            <li>the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;</li>
            <li>the right to object. You have the right to object to our processing of your Personal Data;</li>
            <li>the right of restriction. You have the right to request that we restrict the processing of your personal information;</li>
            <li>the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;</li>
            <li>the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;</li>
        </ol>
        <p>
        Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to provide Service without some necessary data.
        </p>
        <p>
        You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).
        </p>
    `,
    calOPPAContent: `
        <p>CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require a person or company in the United States (and conceivable the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. – See more at: https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/ </p>
        <p>According to CalOPPA we agree to the following:</p>
        <ol class="list-style-lower-alpha">
            <li>users can visit our site anonymously;</li>
            <li>our Privacy Policy link includes the word “Privacy”, and can easily be found on the page specified above on the home page of our website;</li>
            <li>users will be notified of any privacy policy changes on our Privacy Policy Page;</li>
            <li>users are able to change their personal information by emailing us at support@cloquia.com. </li>
        </ol>
        <p>Our Policy on “Do Not Track” Signals:</p>
        <p>We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track browser mechanism is in place. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked. </p>
        <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>
    `,
    serviceProvidersContent: `
        <p>We may employ third party companies and individuals to facilitate our Service (“<b>Service Providers</b>”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</p>
        <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
    `,
    analyticsContent: `
        <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
        <h4>Google Analytics</h4>
        <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</p>
        <p>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en" target="_blank">https://policies.google.com/privacy?hl=en</a></p>
        <p>We also encourage you to review the Google's policy for safeguarding your data: <a href="https://support.google.com/analytics/answer/6004245" target="_blank">https://support.google.com/analytics/answer/6004245.</a></p>
        <h4>Mixpanel</h4>
        <p>Mixpanel is provided by Mixpanel Inc.</p>
        <p>You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page: <a href="https://mixpanel.com/optout/" target="_blank">https://mixpanel.com/optout/</a></p>
        <p>For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel: <a href="https://mixpanel.com/terms/" target="_blank">https://mixpanel.com/terms/</a>
        </p>
    `,
    cicdToolsContent: `
        <p>We may use third-party Service Providers to automate the development process of our Service. </p>
        <h4>GitHub</h4>
        <p>GitHub is provided by GitHub, Inc.</p> 
        <p>GitHub is a development platform to host and review code, manage projects, and build software.</p>
        <p>For more information on what data GitHub collects for what purpose and how the protection of the data is ensured, please visit GitHub Privacy Policy page: <a href="https://help.github.com/en/articles/github-privacy-statement" target="_blank">https://help.github.com/en/articles/github-privacy-statement.</a></p>
    `,
    paymentsContent: `
        <p>We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).</p>
        <p>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
        <p>The payment processors we work with are:</p>
        <h4>Stripe:</h4>
        <p>Their Privacy Policy can be viewed at: <a target="_blank" href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a></p>
    `,
    linkToOtherSitesContent: `
        <p>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
    `,
    childrensPrivacy: "Children's Privacy",
    childrensPrivacyContent: `
        <p>Our Services are not intended for use by children under the age of 18 (“<b>Child</b>” or “<b>Children</b>”). </p>
        <p>We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</p>
    `,
    changesToPrivacyPolicy: 'Changes to This Privacy Policy',
    changesToPrivacyPolicyContent: `
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
    `,
    contactUs: 'Contact Us',
    contactUsContent: `
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <p>By email: <a href="mailto:support@cloquia.com">support@cloquia.com</a>.</p>
        <p>By visiting this page on our website: <a target="_blank" href="https://cloquia.com/contact">https://cloquia.com/contact</a>.</p>
    `,
};

const termsOfuse = {
    introduction: 'Introduction',
    termsAndConditions: 'Terms and Conditions',
    termsOfService: 'Terms Of Service',
    introductionContent: `
        <p>Welcome to <b>Cloquia Inc.</b> (“<b>Company</b>”, “<b>we</b>”, “<b>our</b>”, “<b>us</b>”)! As you have just clicked our Terms of Service, please pause, grab a cup of coffee and carefully read the following pages. It will take you approximately 20 minutes.</p>
        <p>These Terms of Service (“<b>Terms</b>”, “<b>Terms of Service</b>”) govern your use of our web pages located at <a href="https://cloquia.com" target="_blank">https://cloquia.com</a> operated by Cloquia Inc.</p>
        <p>Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages. Please read it here <a href="https://cloquia.com/privacy" target="_blank">https://cloquia.com/privacy.</a></p>
        <p>Your agreement with us includes these Terms and our Privacy Policy (“<b>Agreements</b>”). You acknowledge that you have read and understood Agreements, and agree to be bound of them.</p>
        <p>If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at <a href="mailto:support@cloquia.com">support@cloquia.com</a> so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.</p>
        <p>Thank you for being responsible. </p>
    `,
    communications: 'Communications',
    purchases: 'Purchases',
    subscriptions: 'Subscriptions',
    feeChanges: 'Fee Changes',
    content: 'Content',
    prohibitedUses: 'Prohibited Uses',
    refunds: 'Refunds',
    analytics: 'Analytics',
    noUseByMinors: 'No Use By Minors',
    accounts: 'Accounts',
    intellectualProperty: 'Intellectual Property',
    copyrightPolicy: 'Copyright Policy',
    DMCA: 'DMCA Notice and Procedure for Copyright Infringement Claims',
    errorReporting: 'Error Reporting and Feedback',
    linksToOtherWebSites: 'Links To Other Web Sites',
    disclaimerOfWarranty: 'Disclaimer Of Warranty ',
    limitationOfLiability: 'Limitation Of Liability',
    termination: 'Termination',
    governingLaw: 'Governing Law',
    changesToService: 'Changes To Service',
    amendmentsToTerms: 'Amendments To Terms',
    waiverAndSeverability: 'Waiver And Severability',
    acknowledgement: 'Acknowledgement',
    contactUs: 'Contact Us',
    communicationsContent: `
        <p>
        By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at <a href="mailto:unsubscribe@cloquia.com">unsubscribe@cloquia.com</a>.
        </p>
    `,
    purchasesContent: `
        <p>
        If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.
        </p>
        <p>
        You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.
        </p>
        <p>
        We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.
        </p>
        <p>
        We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.
        </p>
        <p>
        We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected. 
        </p>
    `,
    feeChangesContent: `
        <p>
        Cloquia Inc., in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.
        </p>
        <p>
        Cloquia Inc. will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.
        </p>
        <p>
        Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.
        </p>
    `,
    subscriptionsContent: `
        <p>
        Some parts of Service are billed on a subscription basis (“<b>Subscription(s)</b>”). You will be billed in advance on a recurring and periodic basis (“<b>Billing Cycle</b>”). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.
        </p>
        <p>
        At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Cloquia Inc. cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting Cloquia Inc. customer support team.
        </p>
        <p>
        A valid payment method, including credit card or PayPal, is required to process the payment for your subscription. You shall provide Cloquia Inc. with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize Cloquia Inc. to charge all Subscription fees incurred through your account to any such payment instruments.
        </p>
        <p>
        Should automatic billing fail to occur for any reason, Cloquia Inc. will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
        </p>
    `,
    refundsContent: `
        <p>
        We issue refunds for Contracts within thirty (30) days of the original purchase of the Contract.
        </p>
    `,
    contentText: `
        <p>
        Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (“Content”). You are responsible for Content that you post on or through Service, including its legality, reliability, and appropriateness.
        </p>
        <p>
        By posting Content on or through Service, You represent and warrant that: (i) Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.
        </p>
        <p>You retain any and all of your rights to any Content you submit, post or display on or through Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through Service. However, by posting Content using Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through Service. You agree that this license includes the right for us to make your Content available to other users of Service, who may also use your Content subject to these Terms.</p>
        <p>Cloquia Inc. has the right but not the obligation to monitor and edit all Content provided by users.</p>
        <p>In addition, Content found on or through this Service are the property of Cloquia Inc. or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</p>
    `,
    prohibitedUsesContent: `
        <p>
        You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:
        </p>
        <ol class="list-style-lower-alpha">
            <li>In any way that violates any applicable national or international law or regulation.</li>
            <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</li>
            <li>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.</li>
            <li>To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</li>
            <li>In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</li>
            <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.</li>
        </ol>
        <p>Additionally, you agree not to:</p>
        <ol class="list-style-lower-alpha">
            <li>Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.</li>
            <li>Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.</li>
            <li>Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</li>
            <li>Use any device, software, or routine that interferes with the proper working of Service.</li>
            <li>Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</li>
            <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.</li>
            <li>Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</li>
            <li>Take any action that may damage or falsify Company rating.</li>
            <li>Otherwise attempt to interfere with the proper working of Service.</li>
        </ol>
    `,
    analyticsContent: `
    <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
    <h4><b>Google Analytics</b></h4>
    <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</p>
    <p>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en" target="_blank">https://policies.google.com/privacy?hl=en</a></p>
    <p>We also encourage you to review the Google's policy for safeguarding your data: <a href="https://support.google.com/analytics/answer/6004245" target="_blank">https://support.google.com/analytics/answer/6004245.</a></p>
    <h4><b>Mixpanel</b></h4>
    <p>Mixpanel is provided by Mixpanel Inc.</p>
    <p>You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page: <a href="https://mixpanel.com/optout/" target="_blank">https://mixpanel.com/optout/</a></p> 
    <p>For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel: <a href="https://mixpanel.com/terms/" target="_blank">https://mixpanel.com/terms/</a></p>
    `,
    noUseByMinorsContent: `
    Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using any of Company, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the access and usage of Service.
    `,
    accountsContent: `
    <p>When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.</p>
    <p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
    <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.</p>
    <p>We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</p>
    `,
    intellectualPropertyContent: `
    <p>
    Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Cloquia Inc. and its licensors. Service is protected by copyright, trademark, and other laws of the United States. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Cloquia Inc.
    </p>
    `,
    copyrightPolicyContent: `
        <p>We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights (“<b>Infringement</b>”) of any person or entity.</p>
        <p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to copyright@cloquia.com, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”</p>
        <p>You may be held accountable for damages (including costs and attorneys' fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.</p>
    `,
    DMCAContent: `
    <p>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</p>
    <ol class="list-style-lower-alpha">
        <li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest;</li>
        <li>a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work;</li>
        <li>identification of the URL or other specific location on Service where the material that you claim is infringing is located;</li>
        <li>your address, telephone number, and email address;</li>
        <li>a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
        <li>a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
        
    </ol>
    <p>You can contact our Copyright Agent via email at <a href="mailto:copyright@cloquia.com">copyright@cloquia.com</a></p>
    `,
    errorReportingContent: `
        <p>You may provide us directly at <a href="mailto:support@cloquia.com">support@cloquia.com</a> with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“<b>Feedback</b>”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</p>`,
    linksToOtherWebSitesContent: `
    <p>Our Service may contain links to third party web sites or services that are not owned or controlled by Cloquia Inc.</p>
    <p>Cloquia Inc. has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>
    <p>YOU ACKNOWLEDGE AND AGREE THAT Cloquia Inc. SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.</p>
    <p>WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.</p>
    `,
    disclaimerOfWarrantyContent: `
        <p>THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</p>
        <p>NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p> 
        <p>COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
        <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
    `,
    limitationOfLiabilityContent: `
    <p>EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>
    `,
    terminationContent: `
        <p>We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.</p>
        <p>If you wish to terminate your account, you may simply discontinue using Service.</p>
        <p>All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
    `,
    governingLawContent: `
        <p>These Terms shall be governed and construed in accordance with the laws of State of California without regard to its conflict of law provisions.</p>
        <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.</p>
    `,
    changesToServiceContent: `
        <p>We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.</p>
    `,
    amendmentsToTermsContent: `
        <p>We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</p>
        <p>Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>
        <p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.</p>
    `,
    waiverAndSeverabilityContent: `
        <p>No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision.</p>
        <p>If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.</p>
    `,
    acknowledgementContent: `
    <p>BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</p>
    `,
    contactUsContent: `
        <p>Please send your feedback, comments, requests for technical support:</p>
        <p>By email: <a href="mailto:support@cloquia.com">support@cloquia.com</a>.</p>
        <p>By visiting this page on our website: <a href="https://cloquia.com/contact" target="_blank">https://cloquia.com/contact</a>.</p>
    `,
};
const signup = {
    createAccount: 'Create Account',
    quickAndEasy: "It's quick and easy.",
    firstname: 'First Name',
    lastname: 'Last Name',
    email: 'Email',
    password: 'Password',
    alreadyHaveAcc: 'Already have an account?',
    signin: 'Sign In',
    createFor: 'Create a page for a ',
    renownedScholar: 'renowned scholar',
    university: 'university',
    orOther: 'or other ',
    organizations: 'organizations',
};
const landing = {
    login: 'Log In',
    signup: 'Sign Up',
    landingText: 'Get suport from your research community at every stage of the research cycle.',
    searchPlaceHolder: 'Search Cloquia',
    sliderText: {
        fixedText: 'The easiest way to',
        animatedText: {
            0: 'setup and maintain your personal website',
            1: 'build and maintain your online CV',
            2: 'keep up with the latest research in your field',
            3: 'discover other scholars in your field',
            4: 'share your work and get cited fast!',
            5: 'keep your reference manager up-to-date',
        },
    },
    signIn: 'Sign In',
    createFreeAccount: 'Create free account',
    personalWebsite: 'Personal Website',
    literature: 'Literature',
    literatureSummary: 'Keep up with the latest research in your field.',
    personalWebsiteSummary:
        "Build a personal website in no time. Just add your information on the dashboard, that's it! We take care of the rest.",
    onlineCv: 'Online CV',
    onlineCvSummary: 'Keep your online CV organized and up-to-date.',
    library: 'Library',
    librarySummary:
        'Add your scholarly works and click publish. Your website is updated and your work is accessible to other users on the platform, instantly.',
    people: 'People',
    peopleSummary: 'Discover other scholars who share your research interests.',

    community: 'Community',
    events: 'Events',
    organizeYourLibrary: 'Organize your library',
    updateCvEasily: 'Update CV easily',
    buildYourWebsite: 'Build your website',
    tryCloquia: 'Try Cloquia Today!',
    aboutHeading: 'An efficient way to keep all your professional information up-to-date.',
    aboutText:
        'Your website and CV get instantly updated as you update information on your dashboard.',
    aboutUs: 'About Us',
    blog: 'Blog',
    resources: 'Resources',
    helpCenter: 'Help Center',
    gdprStatement: 'GDPR Statement',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
    cloquia: 'Cloquia',
    aboutSlider: {
        0: {
            heading: 'An efficient way to keep all your professional information up-to-date',
            content: `<div>
            <p>Your website and CV get instantly updated as you update information on your dashboard.</p>
                <ul>
                    <li>No more painful repetitions.</li>
                    <li>Update one, update them all.</li>
                </ul>
            </div>`,
        },
        1: {
            heading: 'Effortlessly organized',
            content: `<div>
                <ul>
                    <li>Discover new people and scholarly works by field of study, subfield, and specialized research topics.</li>
                    <li>Keeping up with the latest in research should not be a chore.</li>
                </ul>
            </div>`,
        },
        2: {
            heading: 'Proactively helpful',
            content:
                '<div><p>Cloquia surfaces in real-time the latest research and newest scholar in your field so you never miss out.</p></div>',
        },
        3: {
            heading: 'A home for everything research-related',
            content: `<div>
            <p>From your publications, teaching portfolio, and datasets — to your library of scholarly works, and your conversations with fellow researchers.</p>
            <p>Everything lives here</p>
            </div>`,
        },
        4: {
            heading: 'A personal library that updates in real-time.',
            content: `<div>
                <ul>
                    <li>No more obsolete versions of manuscripts in your reference manager.</li>
                    <li>Your library gets automatically updated as authors update information about their scholarly works.</li>
                </ul>
            </div>`,
        },
        5: {
            heading: 'A personal website that does its job.',
            content: `<div>
                <p>With Cloquia, your personal website is directly connected to the dashboard of researchers following your work, which means they can instantly discover and access any new research you upload on your website. </p>
                <p>Instant discoverability means more citations for you.</p>
            </div>
            `,
        },
        6: {
            heading: 'You own your data.',
            content: `
                <div>
                <p>Cloquia does not profit from selling your data or use it for advertising. It's your data, period.</p>
                </div>
            `,
        },
    },
};
const setup = {
    setupText: "Let's get you set up!",
    specifyPosition: 'Specify position',
    firstname: 'First Name',
    middlename: 'Middle Name',
    lastname: 'Last Name',
    invalidFirstName: 'Invaid first name',
    invalidMiddleName: 'Invaid middle name',
    invalidLastName: 'Invaid last name',
    inputFirstName: 'Please input first name',
    inputLastName: 'Please input first name',
    requiredError: 'Please input required fields',
    invalidEmail: 'Invalid email',
    emailTaken: 'Email is already registered',
    suffix: 'Suffix',
    whatField: 'What is your field of study?',
    fieldOfStudy: 'Field of study',
    addTopics:
        'Adding research will help you to increase the chances others will discover your work.',
    subfield: 'Subfield',
    addFieldOfStudy: 'Add Field of Study',
    addAnotherFieldOfStudy: 'Add Another Field of Study',
    researchTopics: 'Research topics',
    createWebsite: 'Do you want us to create a personal website for you?',
    dontWorry: "Don't worry if you're not sure; you can edit your preferences anytime.",
    yes: 'Yes, please!',
    no: 'No, thanks! I have a personal website:',
    websitePlaceholder: 'www.yourpersonalwebsitehere.com',
    great: "Great! We'll make sure to link to your website.",
    go: "Let's go!",
    currentlyAffiliated: 'Are you currently affiliated with a college/university?',
    y: 'yes',
    n: 'no',
    country: 'Country',
    collegeOrUniversity: 'College/University',
    whatInstitution: "What's your institution/company name?",
    institutionPlacerholder: 'Institution/company name',
    whatDepartment: "What's your institution/company department name?",
    departmentPlacerholder: 'Department name',
    whatPosition: "What's your position title?",
    positionPlaceholder: 'Position title',
    ok: 'Ok',
    whatContact: "What's your work contact information?",
    street: 'Street address',
    addressline2: 'Address line 2',
    addressline1: 'Address line 1',
    city: 'City',
    state: 'State/Province/Region',
    zip: 'Zip/Postal Code',
    area: 'Area code',
    telephone: 'Telephone number',
    email: 'Email',
    showContactInformationOn: 'Show contact information on:',
    showAddressOnWebsite: 'Show address on personal website',
    showTelephoneOnWebsite: 'Show telephone on personal website',
    skip: 'Skip for now',
    done: 'Done!',
    upload: 'Upload',
    upImg: 'Upload a profile photo',
    bioTips: 'This will show up in your user profile and personal website',
    linkTwitter: 'Link to your Twitter handle',
    showWebsite: 'Show on personal website',
    linkLinkedIn: 'Link to your LinkedIn account',
    linkGitHub: 'Link to your GitHub repository',
    linkGoogleScholar: 'Link to your Google Scholar page',
    linkOrcid: 'Link to your ORCID page',
    undergraduate: 'Undergraduate',
    instructor: 'Instructor',
    masterStudent: "Master's Student",
    lecturer: 'Lecturer',
    phdStudent: 'Ph.D. Student',
    adjunctProfessor: 'Adjunct Professor',
    phdCandidate: 'Ph.D. Candidate',
    visitingProfessor: 'Visiting Professor',
    postdoctoralFellow: 'Postdoctoral Fellow',
    assistantProfessor: 'Assistant Professor',
    researchAssistant: 'Research Assistant',
    associateProfessor: 'Associate Professor',
    technician: 'Technician',
    teachingProfessor: 'Teaching Professor',
    coreFacilityManager: 'Core Facility Manager',
    researchProfessor: 'Research Professor',
    researchScientist: 'Research Scientist',
    professor: 'Professor',
    otherSpecify: 'Other (specify):',
    typeFieldOfStudy: 'Type Field of study',
    typeSubField: 'Type Subfield',
    emeritusProfessor: 'Emeritus Professor',
    saveSuccess: 'Successfully saved!',
    unexpectedError: 'Unexpected error!',
    emptyInfo: 'Empty input!',
    repeatTopic: 'This topic has been added!',
    addLink: '+ Add Link',
    addAnotherLink: '+ Add Another Link',
    addLinkPlaceholder: 'Add Link',
    siteVisibility: 'Site Visibility',
    emptySocialMedia: 'Choose social media profile and provide link',
    personalInformation: 'Personal Information',
    profileInformation: 'Profile Information',
    fullName: 'Full Name',
    positionTitle: 'Position Title',
    currentInstitutionOfAffiliation: 'Current Institution of Affiliation',
    fieldOfStudySubField: 'Field of Study - Subfield',
    profilePicture: 'Profile Picture',
    bio: 'Bio',
    workContactInfo: 'Work Contact Information',
    socialMediaProfiles: 'Social Media Profiles',
    saveChanges: 'Save Changes',
    other: 'Other',
    uploadPhoto: 'Upload Photo',
    addBio: 'Add Bio',
    whatWorkContactInformation: 'What is your work contact information?',
    showOnCV: 'CV',
    showOnWebsite: 'Personal Website',
    address: 'Address',
    optional: '(Optional)',
    github: 'Github',
    googleScholar: 'Google Scholar',
    linkedin: 'Linkedin',
    twitter: 'Twitter',
    orcid: 'OrcId',
    profilePictureSaved: 'Profile picture saved',
    profilePictureRemoved: 'Profile picture removed',
    emailedLink: 'We emailed a magic link to',
    checkInbox: 'Please check your inbox.',
    confirmNewEmail: 'Confirm your new email',
    clickLink: 'Click the link to confirm new email',
    checkSpamInbox:
        'Please also check your spam inbox, if you cannot find the confirmation email that we sent you.',
    linkBroken:
        'Link is broken -- please request a new confirmation link or open the link from another email client',
    sendAnother: 'Send another confirmation link to',
    emailChange: 'Your email is changed to',
    add: 'Add',
    edit: 'Edit',
    save: 'Save',
    removePhoto: 'Remove Photo',
    duplicateSocialMedia: 'Duplicate social media profile!',
};
const dashboard = {
    explore: 'Explore',
    literature: 'Literature',
    people: 'People',
    groups: 'Groups',
    events: 'Events',
    organize: 'Organize',
    calendar: 'Calendar',
    library: 'Library',
    editInfo: 'Personal Information',
    addScholarlyWork: 'Scholarly Work',
    accountSettings: 'Account Settings',
    personalWebsite: 'Personal Website',
    cv: 'CV',
    helpSupport: 'Help & Support',
    logout: 'Logout',
    delete: 'Delete Account',
    deleteFailed: 'Failed to delete your account. Please contact support.',
    deletePrompt: 'Are you sure you want to delete your account? This action is irreversible.',
    deletingPrompt: 'Deleting...',
    deletedPrompt: 'Your Account is Deleted',
    deletedGoBack: 'Go Back to cloquia.com',
    yes: 'Yes',
    no: 'No',
    aiWritingAssistant: 'AI Writing Assistant',
};
const course = {
    courseHeader: 'Add Course',
    courseNumberYearTerm: 'Number,Date, and Term',
    courseTitle: 'Title and Description',
    courseCountryUniversityDepartment: 'Location',
    courseFieldSubResearchTopic: 'Field of Study',
    courseResearchTopic: 'Research Topic',
    courseSyllabus: 'Syllabus',
    courseEvaluation: 'Course Evaluations',
    courseMedia: 'Course Media(Video/Audio/Text)',
    courseMediaTitle: 'Course Media',
    inserVideoAudioText: 'Insert Video/Audio/Text',
    courseLocation: 'Course Location',
    departmentName: 'Department Name',
    typeDepartmentName: 'Type Department Name',
    andOr: 'and/or',
    additionalAttachments: 'Additional Attachments',
};
const people = {
    peopleInField: 'People in your field of study',
    featuredWork: 'Featured work',
    follow: 'Follow',
    title: 'Title: ',
    author: 'Author:',
    summary: 'Summary: ',
    category: 'Category: ',
    addFilter: 'Add filter',
    searchLiterature: 'Search literature',
    following: 'Following',
    followers: 'Followers',
    showOnly: `Show only (select all that apply) ...`,
    searchByName: 'Search by name',
    orSearchBy: 'Or, search by (select all that apply)...',
    firstname: 'Firstname',
    lastname: 'Lastname',
    country: 'Country',
    collegeOrUniversity: 'College/University/Institution',
    positionTitle: 'Position Title',
    fieldOfStudy: 'Field of study',
    subfield: 'Subfield',
    researchTopics: 'Research topics (separated by ',
    any: 'Any',
    ok: 'OK',
    more: 'more',
    hide: 'hide',
    topics: 'Topics',
    viewMore: 'View More',
    viewLess: 'View Less',
};

const literature = {
    addFilter: 'Add filter',
    searchLiterature: 'Search literature',
    newInSubfield: 'New in your subfield',
    significance: 'Significance',
    abstract: 'Abstract',
    filterTitle: 'Find scholarly work',
    withAllWords: 'with all of the words',
    withExact: 'with the exact phrase',
    withLeast: 'with at least one of the words',
    withoutWords: 'without the words',
    whereOccur: 'where my words occur',
    anywhere: 'anywhere in the work',
    inTitle: 'in the title of the work',
    authoredBy: 'Return articles authored by',
    publishIn: 'Return articles published in',
    datedBetween: 'Return articles dated between',
    filterSearchBy: 'Filter search by (select all that apply)...',
    category: 'Category',
    itemType: 'Item Type',
    fieldOfStudy: 'Field of study',
    subfield: 'Subfield',
    researchTopics: 'Research topics (separated by ',
    showOnly: 'Show only works by (select all that apply) ...',
    undergraduate: 'Undergraduate',
    instructor: 'Instructor',
    masterStudent: "Master's Student",
    lecturer: 'Lecturer',
    phdStudent: 'Ph.D. Student',
    adjunctProfessor: 'Adjunct Professor',
    phdCandidate: 'Ph.D. Candidate',
    visitingProfessor: 'Visiting Professor',
    postdoctoralFellow: 'Postdoctoral Fellow',
    assistantProfessor: 'Assistant Professor',
    researchAssistant: 'Research Assistant',
    associateProfessor: 'Associate Professor',
    technician: 'Technician',
    teachingProfessor: 'Teaching Professor',
    coreFacilityManager: 'Core Facility Manager',
    researchProfessor: 'Research Professor',
    researchScientist: 'Research Scientist',
    professor: 'Professor',
    otherSpecify: 'Other (specify):',
    emeritusProfessor: 'Emeritus Professor',
    publication: 'Publications',
    selectItemType: 'Select item type',
    journalArticle: 'Journal Article',
    manuscript: 'Manuscript',
    book: 'Book',
    bookSection: 'Book Section',
    computerProgram: 'Software',
    workInProgress: 'Work in Progress',
    dataset: 'Dataset',
    researchStatement: 'Research Statement',
    courseSyllabus: 'Course Syllabus',
    teachingPhilosophy: 'Teaching Philosophy',
    diversityStatement: 'Diversity Statement',
    artwork: 'Artwork',
    audioRecording: 'Audio Recording',
    bill: 'Bill',
    blogPost: 'Blog Post',
    case: 'Case',
    conferencePaper: 'Conference Paper',
    dictionaryEntry: 'Dictionary Entry',
    document: 'Document',
    email: 'Email',
    encyclopediaArticle: 'Encyclopedia Article',
    film: 'Film',
    forumPost: 'Forum Post',
    hearing: 'Hearing',
    instantMessage: 'Instant Message',
    interview: 'Interview',
    letter: 'Letter',
    magazineArticle: 'Magazine Article',
    map: 'Map',
    newspaperArticle: 'Newspaper Article',
    patent: 'Patent',
    podcast: 'Podcast',
    presentation: 'Presentation',
    radioBroadcast: 'Radio Broadcast',
    report: 'Report',
    statute: 'Statute',
    thesis: 'Thesis',
    tvBroadcast: 'TV Broadcast',
    videoRecording: 'Video Recording',
    webpage: 'Webpage',
    forthcoming: 'Forthcoming',
    kudos: 'Kudos',
    comments: 'Comments',
    download: 'Download',
    save: 'Save to Library',
    removeFromLibrary: 'Remove from Library',
    saves: 'Saves',
};

const addScholarlyWork = {
    selectCategory: 'Select a category',
    research: 'Research',
    teaching: 'Teaching',
    other: 'Other',
    publication: 'Publications',
    publicationStatus: 'Publication Status',
    publicationStatusPlaceholder: 'Example: Under Review',
    publicationLinkTitle: 'Publication',
    publicationLinkTips: 'Add an external link to publication',
    selectItemType: 'Select item type',
    journalArticle: 'Journal Article',
    manuscript: 'Manuscript',
    book: 'Book',
    bookSection: 'Book Chapter/Section',
    computerProgram: 'Software',
    workInProgress: 'Work in Progress',
    dataset: 'Dataset',
    researchStatement: 'Research Statement',
    researchStatements: 'Research Statements',
    courseSyllabus: 'Course',
    teachingPhilosophy: 'Teaching Philosophy',
    diversityStatement: 'Diversity Statement',
    diversityStatements: 'Diversity Statements',
    artwork: 'Artwork',
    audioRecording: 'Audio Recording',
    bill: 'Bill',
    blogPost: 'Blog Post',
    case: 'Case',
    conferencePaper: 'Conference Paper',
    dictionaryEntry: 'Dictionary Entry',
    document: 'Document',
    email: 'Email',
    encyclopediaArticle: 'Encyclopedia Article',
    film: 'Film',
    forumPost: 'Forum Post',
    hearing: 'Hearing',
    instantMessage: 'Instant Message',
    interview: 'Interview',
    letter: 'Letter',
    magazineArticle: 'Magazine Article',
    map: 'Map',
    newspaperArticle: 'Newspaper Article',
    patent: 'Patent',
    podcast: 'Podcast',
    presentation: 'Presentation',
    radioBroadcast: 'Radio Broadcast',
    report: 'Report',
    statute: 'Statute',
    thesis: 'Thesis',
    tvBroadcast: 'TV Broadcast',
    videoRecording: 'Video Recording',
    webpage: 'Webpage',
    itemType: 'Item Type: ',
    journalArticleTips: 'An article published in a scholarly journal (either print or online).',
    titlePlaceholder: 'Type Title',
    abstractPlaceholder: 'Type Abstract',
    significance: 'Significance',
    significancePlaceholder: 'Type Significance',
    publisherTips: 'Type the complete name of the journal',
    publisher: 'Publisher',
    publicationDate: 'Publication Date',
    place: 'Place',
    publisherPlaceholder: 'Example: American Sociological Review',
    placePlacerholder: 'Place* (Example: New York)',
    publicationTips: 'Type the complete name of the journal.',
    publicationPlaceholder: 'Publication* (Example: American Sociological Review)',
    volumePlaceholder: 'Type Volume Number',
    issuePlaceholder: 'Type Issue Number',
    pagesPlaceholder: 'Example: 38-46',
    doiPlaceholder: 'Type DOI',
    additionalFields: 'Atypical additional fields',
    languagePlaceholder: 'Select Language',
    seriesPlaceholder: 'Type Series Number',
    seriesTitlePlaceholder: 'Type Series Title',
    seriesTitle: 'Series Title',
    seriesTextPlaceholder: 'Series Text',
    issnPlaceholder: 'Type ISSN',
    issn: 'ISSN',
    fieldOfStudyPlaceholder: 'Select Field of study*',
    subfieldPlaceHolder: 'Select Subfield',
    researchTopicsPlaceholder: 'Research topics',
    emptyField: 'Field of study must not be empty',
    emptySubfield: 'At least one subfield',
    fieldOfStudy: 'Field of study',
    subfield: 'Subfield',
    researchTopicsLabel: 'Research Topics / Keywords',
    addEntry: 'Add entry',
    saveEntry: 'Save entry',
    addSuccess: 'Successfully added!',
    addFail: 'Failed to add!',
    deleteSuccess: 'Successfully deleted!',
    deleteFail: 'Failed to delete!',
    saveSuccess: 'Successfully saved!',
    editSuccess: 'Successfully edited!',
    saveFail: 'Failed to save!',
    emptyTitle: 'Title must not be empty!',
    countryNameSize: 'Country field should not have more than 200 characters!',
    universityTitleSize: 'College/University should not have more than 200 characters!',
    departmentNameSize: 'Department name should not have more than 200 characters!',
    emptyAbstract: 'Abstract must not be empty!',
    atLeastOneResearchField: 'At least one research field!',
    emptyPublicationTitle: 'Name of the journal must not be empty!',
    uploadFileTitle:
        'Only add work you have created, and only include files if you have the rights to distribute them publicly (for example, a pre-print version), and wish to do so.',
    fileLink: 'Add an external link to file',
    publicationLink: 'Add an external link to publication',
    andOr: 'AND/OR',
    or: 'OR',
    prePrint: 'Pre-print',
    prePrintTips:
        'Only add work you have created, and only include files if you have the rights to distribute them publicly, and wish to do so.',
    prePrintLink: 'Add an external link to pre-print copy',
    uploadFile: 'Upload file',
    uploadImage: 'Upload image',
    uploadImagesTitle:
        "Image is optional but increases the chances of other users' engagement. Typical images include a figure for the main finding, figure for theoretical framework, or a photo motivating the research question.",
    fieldTips:
        'Classifying your work by research topic(s) is optional but increases the chances others will discoverit. You may add more than one field of study and select multiple subfields and research topics in each field of study.',
    addAuthorTips:
        'Add authors in the order that you want it to appear in the citation. Note: You must add yourself as author/coauthor. You can reorder by drag & drop',
    searchContributorPlaceholder: 'Search Cloquia user to add',
    author: 'Author',
    contributor: 'Contributor',
    editor: 'Editor',
    reviewedAuthor: 'Reviewed Author',
    emptyContributor:
        'This user does not have a cloquia account. Do you want to invite him/her to cloquia?',
    repeatContributor: 'Already added this user!',
    inviteTips: `Coauthor not found in Cloquia? Add them manually. (Optional: Click the 'Invite" button to invite them to join Cloquia.)`,
    firstnamePlaceholder: 'Firstname',
    lastnamePlaceholder: 'Lastname',
    displayOnWebsite: 'Make available on your personal website',
    displayOnCv: 'Add to your online CV',
    isFeatured: 'Add as a featured work',
    repeatTopic: 'This topic has been added!',
    emptyInfo: 'Empty input!',
    invite: 'Invite',
    wrongFormat: 'Please check E-mail or Name format!',
    inviteFailed: 'Invite Error!',
    addSelfAsContributor: 'You have to add yourself as a contributor!',
    needUrlOrFile: 'You must at least either fill in the url or upload a file',
    selectYearMonth: 'Select year & month',
    uploadFileFailed: 'Error: Upload files failed!',
    manuscriptTypePlaceHolder: 'Manuscript Type*',
    manuscriptType: 'Manuscript Type',
    edition: 'Edition',
    editionPlaceholder: 'Edition',
    isbn: 'ISBN',
    isbnPlaceholder: 'Type ISBN',
    seriesNumber: 'Series Number',
    seriesNumberPlaceholder: 'Series Number',
    emptyDate: 'Date must be selected!',
    inPreparationForSubmission: 'In preparation for submission',
    underReview: 'Under review',
    reviseResubmit: 'Revise & resubmit',
    forthcoming: 'Forthcoming',
    emptyManuscriptType: 'Please select manuscript type!',
    emptyPublisher: 'Please fill in publisher!',
    emptyPlace: 'Please fill in place!',
    numberOfVolumes: '# of volumes',
    numberOfVolumesPlaceholder: 'Number of volumes',
    numPages: '# of pages',
    numPagesPlaceholder: 'Number of pages',
    descriptionPlaceholder: 'Description*',
    addACourse: 'Add a course',
    canEditAnytime: 'You can edit your preferences in your Library anytime',
    courseNumber: 'Course Number',
    year: 'Year',
    term: 'Term(s)',
    termPlaceholder: 'Example: Fall 2021, 2nd Sem AY20, etc.',
    level: 'Level',
    levelPlaceholder: 'Example: Ph.D., Undergraduate, Masters, etc.',
    position: 'Position',
    positionPlaceholder: 'Example: Professor, Graduate Student Instructor, etc.',
    courseTitle: 'Course Title',
    course: 'Course Description',
    title: 'Title',
    abstract: 'Abstract',
    department: 'department',
    country: 'Country',
    collegeOrUniversity: 'College/University',
    syllabus: 'Syllabus',
    courseEvaluations: 'Course Evaluations',
    courseMedia: 'Course Media (Video/Audio/Text)',
    filename: 'Filename',
    uploadLinkPlaceHolder: 'URL*: www.website.com/li',
    addAnotherFile: 'Add Another File',
    emptyCourseDescription: 'Course Description must not be empty!',
    emptyCourseNumber: 'Course Number must not be empty!',
    emptyCourseYear: 'Please choose Year!',
    emptyCourseTerm: 'Course Term must not be empty!',
    atLeastOneSubfield: 'At least one research subfield!',
    journalArticleDescription:
        'An article published in a scholarly journal (either print or online).',
    manuscriptDescription:
        'An unpublished manuscript. Use this type for both historical documents and modern unpublished work (e.g., unpublished manuscripts, manuscripts submitted for publication, working papers that are not widely available). Can also be used for other forms of historical or archival documents. This item type can also be adapted to fit many types of unusual items.',
    bookDescription:
        'A book or similar published item. For government documents, technical reports, manuals, etc., use Report instead. This item type can also be adapted to fit many types of unusual items.',
    bookSectionDescription:
        'A section of a book. Usually chapters, but also forewords, prefaces, introductions, appendices, afterwords, comments, etc.',
    computerProgramDescription: 'A piece of software, an app, or another computer program.',
    workInProgressDescription: 'A work in progress with no completed output.',
    datasetDescription: 'Dataset',
    researchStatementDescription: 'Research Statement',
    artworkDescription:
        'A piece of artwork (e.g., an oil painting, photograph, or sculpture). Also use this item type for other types of images or visual items (e.g., scientific figures).',
    audioRecordingDescription:
        'Any form of audio recording, including music, spoken word, sound effects, archival recordings, or audio-based scientific figures.',
    billDescription: 'A proposed piece of legislation.',
    blogPostDescription:
        'An article or entry posted to a personal blog website. For online articles published as part of a larger online publication (e.g., NYT Blogs), using Magazine Article or Newspaper Article generally yields better results.',
    caseDescription: 'A legal case, either published or unpublished.',
    conferencePaperDescription:
        'A paper presented at a conference and subsequently published in a formal conference proceedings publication (e.g., as a book, report, or issue of a journal). For conference papers that have not been published in a proceedings, use Presentation.',
    dictionaryEntryDescription: 'An entry published as part of a dictionary.',
    documentDescription:
        'A generic document item. This item type has a poor selection of fields and poor support in citation styles, so it should generally be avoided.',
    emailDescription:
        'A message sent via email. This type could also be used for other forms of personal communication.',
    encyclopediaArticleDescription: 'An article or chapter published as part of an encyclopedia.',
    filmDescription:
        'A film or motion picture. Generally, use this type for artistically-oriented films (including fictional, non-fictional, and documentary films). For other types of video items, use Video Recording.',
    forumPostDescription:
        'A post on an online discussion forum. Also use this type for items such as Facebook posts or tweets.',
    hearingDescription: 'A formal hearing or meeting report by a legislative body.',
    instantMessageDescription:
        'A message sent via an instant message or chat service. This type could also be used for other forms of personal communication.',
    interviewDescription:
        'An interview with a person, including recordings, transcripts, or other records of the interview.',
    letterDescription:
        'A letter sent between persons or organizations. This type could also be used for other forms of personal communication.',
    magazineArticleDescription:
        'An article published in a non-scholarly, popular, or trade magazine (either print or online).',
    mapDescriptionDescription: 'A map. Also use this type for geographic models.',
    newspaperArticleDescription: 'An article published in a newspaper (either print or online).',
    patentDescription: 'A patent awarded for an invention.',
    podcastDescription:
        'A podcast (an episode of an audio or video program distributed online, often via subscription).',
    presentationDescription:
        'A presentation made as part of a conference, meeting, symposium, lecture, etc. This item type refers to the presentation itself, not a written version published as part of a conference proceedings (use Conference Paper for such published versions).',
    radioBroadcastDescription:
        'An audio broadcast, such as a radio news show, an episode of a radio entertainment series, or similar. Includes broadcasts from online radio stations and audio broadcasts archived online (cf. Podcast).',
    reportDescription:
        'A report published by an organization, institution, government department, or similar entity. Also used for working papers and preprints distributed through institutional repositories or preprint servers. This item type can also be adapted to fit many types of unusual items.',
    statuteDescription: 'A law or other piece of enacted legislation.',
    thesisDescription:
        'A thesis submitted as part of a student applying for a degree (either published or unpublished).',
    tvBroadcastDescription: 'An episode of a television series.',
    videoRecordingDescription:
        'A video recording. Use this type for general video items that do not fit into one of the more specific video item types (e.g., Film, TV Broadcast), such as YouTube videos or video-based scientific figures.',
    webpageDescription:
        'An online page of a website. When possible, use one of the more specific item types above (e.g., Magazine Article, Blog Post, Report).',
    institution: 'Institution',
    reportNumber: 'Report Number',
    reportType: 'Report Type',
    thesisType: 'Thesis Type',
    conferenceName: 'Conference Name',
    proceedingsTitle: 'Proceedings Title',
    meetingName: 'Meeting Name',
    presentationType: 'Presentation Type',
    version: 'Version',
    system: 'System',
    workingPaper: 'Working Paper',
    programmingLanguage: 'Programming Language',
    applicationNumber: 'Application Number',
    assignee: 'Assignee',
    attorneyAgent: 'Attorney Agent',
    filingDate: 'Filing Date',
    issueDate: 'Issue Date',
    countryOfIssuance: 'Country Of Issuance',
    issuingAuthority: 'Issuing Authority',
    legalStatus: 'Legal Status',
    peerReviewed: 'Peer reviewed',
    nonPeerReviewed: 'Non-peer reviewed',
    peerReviewStatus: 'Peer Review Status',
    patentNumber: 'Patent Number',
    priorityNumbers: 'Priority Numbers',
    references: 'References',
    artworkMedium: 'Artwork Medium',
    artworkSize: 'Artwork Size',
    audioRecordingFormat: 'Audio Recording Format',
    label: 'Label',
    runningTime: 'Running Time',
    episodeNumber: 'Episode Number',
    guest: 'Guest',
    videoRecordingFormat: 'Video Recording Format',
    genre: 'Genre',
    distributor: 'Distributor',
    network: 'Network',
    programTitle: 'Program Title',
    studio: 'Studio',
    billNumber: 'Bill Number',
    code: 'Code',
    codePages: 'Code Pages',
    codeVolume: 'Code Volume',
    history: 'History',
    legislativeBody: 'Legislative Body',
    section: 'Section',
    session: 'Session',
    documentNumber: 'Document Number',
    committee: 'Committee',
    codeNumber: 'Code Number',
    nameOfAct: 'Name Of Act',
    publicLawNumber: 'Public Law Number',
    dateEnacted: 'dateEnacted',
    caseName: 'Case Name',
    counsel: 'Counsel',
    court: 'Court',
    dateDecided: 'Date Decided',
    docketNumber: 'Docket Number',
    firstPage: 'First Page',
    reporter: 'Reporter',
    reporterVolume: 'Reporter Volume',
    commenter: 'Commenter',
    websiteType: 'Website Type',
    websiteTitle: 'Website Title',
    forumTitle: 'Forum Title',
    postType: 'Post Type',
    recipient: 'Recipient',
    letterType: 'Letter Type',
    dictionaryTitle: 'Dictionary Title',
    encyclopediaTitle: 'Encyclopedia Title',
    interviewee: 'Interviewee',
    interviewMedium: 'Interview Medium',
    mapType: 'Map Type',
    scale: 'Scale',
    bookTitle: 'Book Title',
    addAScholarlyWork: 'Add a Scholarly Work',
    addYourResearchStatement: 'Add your Research Statement',
    addResearchStatements: 'Add Research Statements',
    addYourTeachingPhilosophy: 'Add your Teaching Statement',
    addTeachingPhilosophy: 'Add Teaching Philosophy',
    addYourDiversityStatement: 'Add your Diversity Statement',
    addDiversityStatements: 'Add Diversity Statements',
    editYourPreference: 'You can edit your preference in your Library anytime.',
    emptyResearchStatement: 'Research Statement must not be empty!',
    emptyTeachingStatement: 'Teaching Statement must not be empty!',
    emptyDiversityStatement: 'Diversity Statement must not be empty!',
    university: 'University',
    alreadyUploaded:
        'You have already  uploaded a file, please delete it in library in order to upload again.',
    invitewithoutemail: 'Invite without email',
    cancel: 'Cancel',
    singlePublication: 'Publication',
    add: 'Add',
    step: 'Step',
    information: 'Information',
    back: 'Back',
    next: 'Next',
    publish: 'Publish',
    published: 'Published',
    doiAndLanguage: 'DOI and Language',
    doi: 'DOI',
    language: 'Language',
    volumeIssuePages: 'Volume, Issue, and Pages',
    volumeEditionIssuePages: 'Volume, Edition, and # of Volumes and Pages',
    volumeNumber: 'Volume Number',
    issueNumber: 'Issue Number',
    pages: 'Pages',
    seriesDetails: 'Series Details',
    titleAbstractHeading: 'Title, Abstract and Significance',
    words: 'Words',
    characters: 'Characters',
    addJournalArticleStep: 'Add Journal Article (Step {Number})',
    authorCoauthor: 'Author/Coauthor',
    searchForNames: 'Search for names',
    firstname: 'First Name',
    lastname: 'Last Name',
    enterEmail: 'Enter Email',
    dontSendEmailInvite: "Don't send an email invite.",
    role: 'Role',
    inviteSent: 'Invite Sent',
    subfields: 'Subfield(s)',
    addFieldOfStudy: 'Add Another Field of Study',
    attachFileLable: 'Attach Copy of Scholarly Work',
    nameOfAttachment: 'Name of the attachment',
    additionalAttachments: 'Additional Attachments',
    additionalAttachmentsTips:
        'Examples: Pre-analysis Plan, Replication Files, Media Coverage, etc.',
    teachingAdditionalFileTips: 'Examples: Lecture Slides, Resources, etc.',
    addAdditionalAttachments: '+ Add Additional Attachments',
    image: 'Image',
    visibility: 'Visibility',
    month: 'Month',
    bookPublisherPlaceholder: 'e.g. Cam',
    name: 'Name',
    attachFiles: 'Attach Files',
    titleAndAbstract: 'Title, and Abstract',
    description: 'Description',
    typeDescription: 'Type Description',
    startDate: 'Start Date',
    saveChanges: 'Save Changes',
    researchGeneralDescription: 'Research General Description',
    teachingGeneralDescription: 'Teaching General Description',
    otherWorksGeneralDescription: 'Other Works General Description',
    otherWorks: 'Other Work',
    excerpt: 'Excerpt',
    titleAndExcerpt: 'Title and Excerpt',
    dragAndDropUser: 'Drag and drop user names to reorder list of authors.',
};

const library = {
    references: 'Saved References',
    myScholarlyWorks: 'Your Scholarly Work',
    other: 'Other',
    publication: 'Publications',
    otherWorks: 'Other Works',
    singlePublication: 'Publication',
    selectItemType: 'Select item type',
    journalArticle: 'Journal Article',
    manuscript: 'Manuscript',
    book: 'Book',
    bookSection: 'Book Chapter/Section',
    computerProgram: 'Software',
    workInProgress: 'Work in Progress',
    dataset: 'Dataset',
    researchStatement: 'Research Statement',
    courseSyllabus: 'Course Syllabus',
    teachingPhilosophy: 'Teaching Philosophy',
    diversityStatement: 'Diversity Statement',
    filterSearchBy: 'Filter search by (select all that apply)...',
    researchTopics: 'Research topics (separated by ',
    artwork: 'Artwork',
    audioRecording: 'Audio Recording',
    bill: 'Bill',
    blogPost: 'Blog Post',
    case: 'Case',
    conferencePaper: 'Conference Paper',
    dictionaryEntry: 'Dictionary Entry',
    document: 'Document',
    email: 'Email',
    encyclopediaArticle: 'Encyclopedia Article',
    film: 'Film',
    forumPost: 'Forum Post',
    hearing: 'Hearing',
    instantMessage: 'Instant Message',
    interview: 'Interview',
    letter: 'Letter',
    magazineArticle: 'Magazine Article',
    map: 'Map',
    newspaperArticle: 'Newspaper Article',
    patent: 'Patent',
    podcast: 'Podcast',
    presentation: 'Presentation',
    radioBroadcast: 'Radio Broadcast',
    report: 'Report',
    statute: 'Statute',
    thesis: 'Thesis',
    tvBroadcast: 'TV Broadcast',
    videoRecording: 'Video Recording',
    webpage: 'Webpage',
    teaching: 'Teaching',
    workingPaper: 'Working Paper',
    library: 'Library',
    deleteReference: 'Delete Reference!',
    deleteReferenceTxt:
        'If you delete your Reference, it will dispear from your reference list, would you like to continue?',
    deletePublication: 'Delete {type}!',
    deletePublicationText:
        'If you delete your {type}, it will dispear from your publications list, would you like to continue?',
    deleteLiterature: 'Delete {category}!',
    deleteLiteratureText:
        'If you delete your {category}, it will dispear from your {category} list, would you like to continue?',
    confirm: 'Confirm',
    cancel: 'Cancel',
    selectPublicationType: 'Select Publication Type',
    add: 'Add',
    warningSelectPublicationType: 'Select Publication Type',
    name: 'Name',
    description: 'Description',
    type: 'Type',
    year: 'Year',
    draftLable: '[Draft]',
    generalDescription: 'General Description',
    excerpt: 'Excerpt',
};
const cv = {
    buildCv: 'Build your CV',
    workTitle: 'Work',
    isThisAcademic: 'Is this an academic appointment/position?',
    yes: 'Yes',
    no: 'No',
    country: 'Country',
    college: 'College/University',
    departmentName: 'Department Name',
    selectCountry: 'Select Country',
    selectCollege: 'Select College/University',
    typeDepartmentName: 'Type Department Name',
    whatPositionTitle: 'What is your position title?',
    city: 'City/town',
    company: 'Company',
    typeCity: 'Type City/Town',
    typeCompany: 'Type Company Name',
    typePositionTitle: 'Type your position title',
    jobDescription: 'Job Description',
    typeJobDescription: 'Type Job Description',
    addJobDescription: 'Add a Job Description',
    addAchievement: 'Add Another Achievement',
    achievements: 'Achievements',
    typeAchievements: 'Type your Achievements',
    timePeriod: 'Time Period',
    currentlyWorkHere: 'I currently work here',
    date: 'Date',
    to: 'To',
    from: 'From',
    deleteWorkplace: 'Delete Work',
    addAnotherWork: 'Add Another Work Experience (Academic)',
    addAnotherWorkOther: 'Add Another Work Experience (Other)',
    education: 'Education',
    attendedFor: 'Attended for',
    uni: 'College',
    graduateSchool: 'Graduate school',
    degree: 'Degree',
    typeDegree: 'Ph.D. in Public Policy & Political Science',
    fieldOfStudy: 'Field of Study',
    selectFieldOfStudy: 'Select Field of Study',
    subfields: 'Subfields',
    selectSubfields: 'Select Subfield(s)',
    addAFieldOfStudy: 'Add a Field of Study',
    thesis: 'Thesis',
    dissertation: 'Dissertation',
    thesisTitle: 'Thesis Title',
    dissertationTitle: 'Dissertation Title',
    committee: 'Committee',
    typeThesisTitle: 'Type Thesis Title',
    typeDissertationTitle: 'Type Dissertation Title',
    latinHonors: 'Latin Honors',
    honors: 'Honors/Distinctions/GPA/Membership in Honor Societies/Etc.',
    addLatinHonors: 'Add Latin Honors',
    addHonors: 'Add Another Academic Distinctions',
    addAnotherDegree: 'Add Another Education',
    deleteDegree: 'Delete Education',
    affiliations: 'Affiliations',
    organizationTitle: 'Organization Title',
    organization: 'Organization (Example: Evidence in Governance and Politics (EGAP))',
    typeOrganization: 'Ex: Evidence in Governance and Politics (EGAP)',
    positionTitle: 'Position Title',
    addAnotherAffiliation: 'Add another Affiliation',
    deleteAffiliation: 'Delete Affiliation',
    researchGrants: 'Research Grants',
    researchGrant: 'Research Grant',
    grant: 'Grant Title',
    typeGrantName: 'Ex: National Science Foundation (NSF) Research Grant',
    fundingAmount: 'Funding Amount',
    typeAmount: 'Type Amount',
    addGrantCoRecipients: 'Add grant co-recipient(s)',
    emptyCoRecipient: 'Empty co-recipient',
    typeEmail: 'Type name or email',
    email: 'Email',
    firstname: 'First Name',
    lastname: 'Last Name',
    adverName: 'Student Name and Email',
    activeGrant: 'Active Grant',
    addAnotherResearchGrant: 'Add another Research Grant',
    deleteResearchGrant: 'Delete Research Grant',
    awardsFellowships: 'Awards & Fellowships',
    awardName: 'Name of Award or Fellowship',
    typeAwardName: 'Ex: Guggenheim Fellowship or Best Thesis Award',
    awardOrganization: 'Award-Giving Organization/Institution',
    optional: '(Optional)',
    typeOrganizationInstitution: 'Type Award-Giving Organization/Institution',
    awardLocation: 'Award Location (if applicable)',
    typeAwardLocation: 'Type Award Location',
    awardDescription: 'Description',
    typeAwardDescription: 'Type Description',
    award: 'Award',
    honorsAwardsFellowships: 'Honors, Awards & Fellowships',
    awardAndFellowship: 'Award & Fellowship',
    awardAndFellowships: 'Award & Fellowships',
    awardDate: 'Award & Fellowship Date or Duration',
    deleteAward: 'Delete Award or Fellowship',
    addAnotherAward: 'Add Another Award & Fellowship',
    presentations: 'Presentations',
    category: 'Category',
    serviceType: 'Service Type',
    annualMeeting: 'Annual Meetings',
    workshops: 'Workshops',
    invitedTalks: 'Invited Talks',
    invitedPresentations: 'Invited Presentations',
    otherCategory: 'Other Category (specify): ',
    other: 'Other',
    typeCategory: 'Type Category',
    eventName: 'Event Name',
    typeEventName: 'Ex: 117th APSA Annual Meeting & Exhibition',
    eventLocation: 'Event Location (Example: Seattle, WA)',
    typeEventLocation: 'Type Event Location',
    presentationTitle: 'Title of Presentation',
    typePresentationTitle: 'Type Title of Presentation',
    presentationDescription: 'Description',
    eventDate: 'Event Date or Duration',
    or: 'Or',
    deletePresentation: 'Delete Presentation',
    addAnotherPresentation: 'Add another Presentation',
    service: 'Service',
    professional: 'Professional',
    university: 'University/Departmental',
    role: 'Role',
    typeServiceDesciption: 'Type Service Description',
    serviceTypeOtherPlaceholder: 'Example: Community Service',
    serviceOrganization: 'Service-Requiring Organization/Institution',
    typeServiceOrganization: 'Type Organization/Institution',
    serviceLocation: 'Service Location',
    typeServiceLocation: 'Type Service Location',
    serviceDate: 'Service Date or Duration',
    addAnotherService: 'Add another Service',
    deleteService: 'Delete Service',
    fieldResearch: 'Field Research',
    fieldResearchDescription: 'Field Research Description',
    typeFieldResearchDescription: 'Type Field Research Description',
    addFieldResearchDescription: 'Add Field Research Description',
    uploadAnImage: 'Upload an image',
    uploadImage: 'Upload image',
    inProgress: 'In progress',
    addAnotherFieldResearch: 'Add another Field Research',
    deleteFieldResearch: 'Delete Field Research',
    advising: 'Students/Advising',
    firstPlacementPosition: 'First Placement Position',
    typePlacementPosition: 'Ex: Postdoctoral Fellow',
    firstPlacementOrganization: 'First Placement Organization',
    typePlacementOrganization: 'Ex: Princeton University',
    deleteAdvisee: 'Delete Students/Advising',
    addAnotherAdvisee: 'Add another Students/Advising',
    skills: 'Skills/Experience/Other Training',
    skill: 'Skill or Competency',
    typeSkill: 'Type Skill or Competency',
    rating: 'Rating',
    deleteSkill: 'Delete Skill or Compentency',
    addAnotherSkill: 'Add another Skill/Experience/Other training',
    save: 'Save',
    saveSuccess: 'Successfully saved!',
    deleteSuccess: 'Delete successfully!',
    deleteFail: 'Failed to delete!',
    saveFail: 'Failed to save!',
    editSuccess: 'Successfully edited!',
    cv: 'CV',
    lastName: 'Last Name',
    peerReviewedArticles: 'Peer-Reviewed Articles',
    nonPeerReviewedArticles: 'Non-peer reviewed Articles',
    bookChapters: 'Book Chapters',
    books: 'Books',
    ongoingResearch: 'Ongoing Research',
    teaching: 'Teaching',
    specializedTraining: 'Specialized Training',
    otherResearchWorkExprience: 'Other Research Work Experience',
    workingPapers: 'Working Papers',
    phdCommitees: 'PhD Committees',
    academicAppointments: 'Academic Appointments',
    article: 'Article',
    vol: 'Vol',
    pp: 'pp',
    present: 'Present',
    forthcoming: 'Forthcoming',
    peerReviewed: 'Peer reviewed',
    nonPeerReviewed: 'Non-peer reviewed',
    otherResearchWorkExperience: 'Other Research Work Experience',
    back: 'Back',
    workExperience: 'Work Experience',
    academic: 'Academic',
    nonAcademic: 'Non-Academic',
    typeOfWork: 'Type of Work/Employment',
    add: 'Add',
    work: 'Work',
    location: 'Location',
    collegeOrUniversity: 'College/University',
    collegeOrUniversityPlaceholder: 'Select College/University',
    positionTitlePlaceholder: 'Select your position title',
    otherSpecify: 'Other (specify)',
    undergraduate: 'Undergraduate',
    instructor: 'Instructor',
    masterStudent: "Master's Student",
    lecturer: 'Lecturer',
    phdStudent: 'Ph.D. Student',
    phd: 'Ph.D.',
    masters: 'Masters',
    studentLevel: 'Student Level',
    adjunctProfessor: 'Adjunct Professor',
    phdCandidate: 'Ph.D. Candidate',
    visitingProfessor: 'Visiting Professor',
    postdoctoralFellow: 'Postdoctoral Fellow',
    assistantProfessor: 'Assistant Professor',
    researchAssistant: 'Research Assistant',
    associateProfessor: 'Associate Professor',
    technician: 'Technician',
    teachingProfessor: 'Teaching Professor',
    coreFacilityManager: 'Core Facility Manager',
    researchProfessor: 'Research Professor',
    researchScientist: 'Research Scientist',
    professor: 'Professor',
    emeritusProfessor: 'Emeritus Professor',
    startDate: 'Start Date',
    endDate: 'End Date',
    companyName: 'Company Name',
    publish: 'Publish',
    researchType: 'Research Type',
    academicDistinctions: 'Academic Distinctions',
    details: 'Details',
    typeDetails: 'Type Details of Category',
    addCategory: 'Add Category',
    affiliation: 'Affiliation',
    currency: 'Currency',
    year: 'Year',
    presentation: 'Presentation',
    typeRole: 'Ex: Panel Organizer, Referee, Selection Committee Chair, etc.',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    languages: 'Languages',
    technical: 'Technical',
    interpersonal: 'Interpersonal',
    softwares: 'Softwares',
    currentlyAffiliated: 'I’m currently affiliated',
    edit: 'Edit',
    hideFromCv: 'Hide from CV',
    displayOnCv: 'Display on CV',
    delete: 'Delete',
    deleteItem: 'Delete Item',
    areYouSure: 'Are you sure?',
    currentAdvisee: 'Current advisee',
    dateExpected: 'Date Expected',
    characters: 'characters',
    otherWorkExperience: 'Work Experience (Other)',
    academicWorkExperience: 'Work Experience (Academic)',
    uploadCV: 'Upload CV',
    uploadCVSuccedd: 'CV uploaded successfully!',
    cumLaude: 'Example: Cum Laude | Phi Beta Kappa',
};
const personalWebsite = {
    home: 'Home',
    research: 'Research',
    teaching: 'Teaching',
    cvBio: 'CV/BIO',
    scsdi: 'SCSDI',
    contact: 'Contact',
    email: 'Email',
    'e-mail': 'E-mail',
    github: 'GitHub',
    twitter: 'Twitter',
    linkedin: 'LinkedIn',
    googleScholar: 'Google Scholar',
    orcid: 'ORCID',
    address: 'Address',
    office: 'Office',
    mobile: 'Mobile',
    phone: 'Phone',
    website: 'Website',
    books: 'Books',
    book: 'Book',
    journalArticles: 'Journal Articles',
    journalArticle: 'Journal Article',
    workingPapers: 'Working Papers',
    workingPaper: 'Working Paper',
    worksInProgress: 'Works in Progress',
    item: 'item',
    researchStatement: 'Research Statement',
    courses: 'Courses',
    evaluation: 'Evaluations',
    other: 'Other',
    authors: 'Authors',
    author: 'Author',
    published: 'Published',
    copyright: name => `${new Date().getFullYear()} ${name}. All Rights Reserved.`,
    poweredBy: 'Powered by ',
    cv: 'CV',
    academicAppointments: 'Academic Appointments',
    education: 'Education',
    peerReviewed: 'Peer reviewed',
    nonPeerReviewed: 'Non-peer reviewed',
    peerReviewedArticles: 'Peer-Reviewed Articles',
    bookChapters: 'Book Chapters',
    nonPeerReviewedArticles: 'Non-peer-reviewed Articles',
    policyWritingComentary: 'Policy Writing & Comentary',
    ongoingResearch: 'Ongoing Research',
    researchGrants: 'Research Grants & Contracts',
    awardsFellowships: 'Awards & Fellowships',
    specializedTraining: 'Specialized Training',
    otherResearchWorkExprience: 'Other Research Work Experience',
    otherWorkExprience: 'Work Experience (Other)',
    presentations: 'Presentations & Talks',
    affiliations: 'Affiliations',
    professionalService: 'Professional Service',
    phdCommitees: 'PhD Committees',
    skills: 'Skills/Experience/Other Training',
    description: 'Description',
    syllabusDownloadText: '[Syllabus]',
    evaluationsDownloadText: '[Course Evaluations]',
    download: '[download]',
    photoDownloadImage: '[photo]',
    publicationDownloadText: '[Publication]',
    prePrintDownloadText: '[Pre-print]',
    teachingPhilosophy: 'Teaching Philosophy',
    diversityStatement: 'Diversity Statement',
    noFileToDownload: 'No file to download!',
    present: 'Present',
    spring: 'Srping',
    summer: 'Summer',
    fall: 'Fall',
    winter: 'Winter',
    service: 'Service',
    forthcoming: 'Forthcoming',
    bookSection: 'bookSection',
    abstract: 'Abstract',
    bookChapter: 'Book Chapter',
    workInProgress: 'Work in Progress',
    journal: 'Journal',
    publisher: 'Publisher',
    in: 'In',
    advising: 'Students/Advising',
    prePrint: 'Pre-print',
    publication: 'Publication',
    additionalAttachment: 'Additional Attachment',
    honorsAwardsFellowships: 'Honors, Awards & Fellowships',
    lastUpdate: 'Last Update',
    downloadCV: '[Download CV]',
    distinctions: 'Distinctions',
    thesis: 'Thesis',
    dissertation: 'Dissertation',
    committee: 'Committee',
    students: 'Students',
    level: 'Level',
    position: 'Position',
    terms: 'Term(s)',
    otherWorks: 'Other Works',
};
const personalWebsiteDashbord = {
    personalWebsite: 'Personal Website',
    generalSettings: 'General Settings',
    siteAddress: 'Site address',
    update: 'Update',
    publishWebsite: 'Publish Website',
    publish: 'Publish',
    unPublish: 'Unpublish',
    invalidWebsite: 'Please enter valid site address.',
    addWebsite: 'Please add site address.',
    websitePublished: 'Website Published!',
    lastPublishedOn: 'Last published on',
    typeSiteAddress: 'Type site address.',
    seo: 'SEO',
    siteDescription: 'Site Description',
    appearsSearchEngine: 'Appears in search engine results',
    typesiteDescription: 'Type site description',
    metaKeywords: 'Meta keywords',
    separateEachKeword: 'Separate each keword with a comma',
    typeMetaKeywords: 'Type meta keywords',
    footerCode: 'Footer code',
    example: 'Example:',
    googleAnalytics: 'Google analytics',
    trackingCode: 'tracking code',
    typeAbstract: 'Type Abstract',
    theme: 'Theme',
    pages: 'Pages',
    classic: 'Classic',
    sidebarClassic: 'Sidebar Classic',
    customLogo: 'Custom logo',
    willAppearRightMost: 'Will appear on the right-most corner of footer section',
    haderPhoto: 'Header photo',
    homePageOnly: 'Home page only',
    allPages: 'All pages',
    applyTo: 'Apply to',
    adjustOpacity: 'Adjust opacity',
    headerSection: 'Header section',
    changeColor: 'Change color to (use 6-digit hex code)',
    footerSection: 'Footer section',
    enterHexCode: '# Enter hex code',
    saveChanges: 'Save Changes',
    siteAlreadyExist: 'Entered site address is already exist.',
    customLogoSaved: 'Custom Logo Saved!',
    headerPhotoSaved: 'Header Photo Saved!',
    logoDeleted: 'Custom logo deleted!',
    headerImageDeleted: 'Header image deleted!',
    somethingWrong: 'Something went wrong!',
    validHexCode: 'Please enter valid hex color code.',
    publishValidation: 'You have changed your site address, but it is not published.',
    pleasePublishSite:
        'You have changed your site address, but it is not published. Please publish it before save.',
    replaceLogo: 'Replace Logo',
    replacePhoto: 'Replace Photo',
    delete: 'Delete',
    font: 'Font',
    selectTheme: 'Please select theme!',
    selectFont: 'Select Font',
    siteTitleFont: 'Site title font',
    headerSectionFont: 'Header section font',
    footerSectionFont: 'Footer section font',
    pageTitlesFont: 'Page titles font',
    linksFont: 'Links font',
};

const godUser = {
    aiWriting: 'AI Writing Assistant',
    uploadCSVFile: 'Upload CSV file of training data',
    fineTune: 'Perform Fine-tune',
    prompt: 'Prompt',
    addPromptText: 'Add Prompt Text Here',
    response: 'Response',
    completionHere: 'You will get completion response here',
    models: 'Models',
    listOfModels: 'List of fine-tunned models',
    modelName: 'Model Name',
    giveModelName: 'Give custom name to your fine-tune model',
    addCustomModel: 'Please add model name before tunning data.',
    playground: 'Playground',
    fineTuneCompleted: 'Fine-tunning of your model is successfully performed.',
    fineTuneFailure: 'Fine-tunning of your model is failed.',
    fineTuneYourData: 'Fine-tune your data',
    getResult: 'Get Result',
    modelIsNotReady: 'Your model is getting tunned, please try after sometime.',
    selectPromptAndModel: 'Please select Model and add Prompt to get result.',
    somethignWentWrong: 'Something went wrong!',
    modelIsNotReadyForResponse: 'Model is not ready to response now, please try after some time.',
    maxTokens: 'Maximum 250 tokens(words) are allowed in prompt.',
    stopSequences: 'Stop sequences',
    enterSequence: 'Enter sequence and press Tab',
    maxChars: 'Maximum 1000 characters are allowed in prompt.',
};

const general = {
    login,
    search: 'Search',
    upgradeAccount: 'Upgrade Your Account',
    headerHello: 'Hello, ',
    upgradeTxt: 'Upgrade Your Account',
    upgrade: 'Upgrade',
    noFileToDownload: 'No file to download!',
};

const subscription = {
    back: 'Back',
    free: 'Free',
    checkout: 'Checkout',
    step: 'Step',
    upgradeAccountHeading: 'Upgrade your Cloquia account',
    selectPlan: 'Select Plan',
    unavailable: 'Unavailable',
    faqHeading: 'Frequently asked questions',
    personalWebsiteAndCV: 'Personal Website & CV',
    aiWrittingAssitantCredits: 'AI Writing Assistant Credits',
    domain: 'Domain',
    storageSpace: 'Storage Space',
    premiumWebsiteThemes: 'Premium website themes',
    cvDesignTemplates: 'CV design templates',
    removeCloquiaBranding: 'Remove Cloquia branding',
    chatEmailSupport: 'Chat & email support',
    monthTrial: '1 month trial',
    tenCredits: '10 Credits',
    cloquiaSubdomain: 'Cloquia subdomain',
    classicTemplate: '1 Classic template',
    academicCVTemplate: '1 Academic CV template',
    FreeMB: '50 MB',
    reviewCart: 'Review Cart',
    FAQs: {
        1: {
            q: 'Can I pay for my plan monthly?',
            a: 'Yes! We do offer monthly payment options, but you’ll save money with an annual plan.',
        },
        2: {
            q: 'How will I be billed?',
            a: 'Your plan will automatically renew at the end of the subscription.',
        },
        3: {
            q: 'Can I transfer in my domain name?',
            a: 'Yes! We accept .com, .net, and .org domain name transfers. Depending on the circumstances, your current registrar may place temporary restrictions on domain transfers. You should check with their support team first if you aren’t sure. ',
        },
        4: {
            q: 'Can I cancel at any time?',
            a: 'You can cancel from within your account at any point during your subscription. Canceled services will remain active through the expiration date, and you can always reactivate before then if you change your mind.',
        },
        5: {
            q: 'What is your refund policy?',
            a: 'We offer a no-hassle 30 day money back guarantee for our paid plans. If you’d like to get a refund, just reach out to our customer success team.',
        },
        6: {
            q: 'What if I want to switch to a different plan?',
            a: 'You can upgrade your plan at any time from within your account. The cost will be prorated based on the time remaining in your previous subscription.',
        },
    },
    continueToCheckout: 'Continue to checkout',
    checkoutNotes: "30-day money back guarantee if you're not satisfed",
    orderSummary: 'Order Summary',
    forYourSite: 'For your Cloquia {plan} Website',
    total: 'Total',
    month: 'month',
    months: 'months',
    year: 'year',
    years: 'years',
    save: 'Save',
    chooseTermLength: 'Choose your term length',
    thankyouForPurchase: "Thank You! You've purchased a Cloquia {planName} Plan.",
    chargesToCard: 'Charged to your {cardName} ending in {cardLast4}',
    weSentAnEmail: 'We sent an email to',
    whatsNext: "What's next",
    whatsNextText:
        'Please be on the look out for an email from us, with instructions on how to register and connect your custom domain to your Cloquia Personal Website.',
    done: 'Done',
    termsService:
        'By submitting, you agree to our Term of Service & Privacy Notice. Your subscription is set to automatically renew and your selected payment method will be charged (plus any applicable taxes) every {termLength} until you cancel your subscription. You may cancel your subscription at any time from your account settings or by contacting us.',
    purchase: 'Purchase',
    countryRequired: 'Country is required',
    payment: 'Payment',
    confirmation: 'Confirmation',
    processing: 'Processing please wait...',
    cardNumber: 'Card Number',
    expirationDate: 'Expiration Date',
    cvv: 'CVV',
    zipCode: 'Zip Code',
    country: 'Country',
    billingAddress: 'Billing Address',
    firstName: 'First Name',
    lastName: 'Last Name',
    address: 'Address',
    city: 'City',
    state: 'State',
    companyName: 'Company Name (Optional)',
    taxID: 'Tax ID (Optional)',
    onemonth: '1 month',
    oneyear: '1 year',
    twoyears: '2 years',
    currentPlan: 'Current Plan',
    cancelSubscription: 'Cancel Subscription',
    confirmCancelSubscription: 'Are you sure to cancel subscription?',
    downgrade: 'Downgrade',
    upgrade: 'Upgrade',
    downgradeSubscription: 'Downgrade Cloquia Account',
    confirmDowngradeSubscription: 'Are you sure to downgrade your Cloquia Account?',
    downgradeSuccess: 'You are downgraded to Basic plan',
    cancelSubscriptionSuccess: 'Successfully canceled subscription',
    resetsEveryMonth: '*Resets every month',
    coversCustomDomains: '**Covers custom domains costing less than $15 only.',
    freeForever: 'Free Forever',
};

const support = {
    helpSupport: 'Help & Support',
    loveToHear: "We'd love to hear from you!",
    forHelpEmail: 'For help using Cloquia, general enquiries, or just chat, please email:',
};

const aiWritingAssistant = {
    whatWouldYouLike: '1. What would you like to do?',
    researchQuestion: '2. What is/are your research question(s)?',
    whatAreTheTheoretical:
        '3. What is/are the theoretical and/or empirical contribution(s) of your paper?',
    makeADraft: 'Make a Draft',
    creditsRemaining: 'Credits remaining. Need More?',
    upgradeNow: 'Upgrade now!',
    spendLessTime: 'Spend less time writing and more time doing research!',
    copy: 'Copy',
    upgrade: 'Upgrade',
    draftPara: 'Draft introductory paragraph',
    introduceArticle: `Introduce the article's theory`,
    brainStrome: 'Brainstorm contribution to the literature',
    pleaseSelectFromList: 'Please select from the list',
    writeTheIntroductoryPara:
        'Write the introductory paragraph of a journal article. This paragraph tells the reader what the topic is and why it’s interesting or important. This is generally accomplished with a strong opening hook.',
    paragraphOfJournalArticle:
        'Write the paragraph of a journal article introducing the new theory. This paragraph tells the reader how this article fills the gap in the literature.',
    paragraphArticulating:
        'Write the paragraph articulating the scholarly contributions of a journal article. This paragraph tells the reader why the research is interesting and important, and what the implications of the results are. This is generally accomplished by explaining how the article fills the gap in the literature.',
    generateResult: 'Generate Result',
    dataSaved: 'Data saved successfully!',
    resultGenerated: 'Result is generated successfully!',
    bothShouldHaveValues: 'Please add data in section 2 and section 3 to get proper result.',
    addAnotherQuestion: 'Add another research question',
    theResearchQues: 'The research question(s) is/are:',
    addTheoretical: 'Add another theoretical/empirical contribution',
    theNewTheory: 'The new theory is/are:',
    pleaseSelectWhatWhouldYou: 'Please select what would you like to do?',
    response: 'Response:',
    responseCopied: 'Response Copied Successfully!',
    save: 'Save',
    thisContentIsUnsafe: 'This content is unsafe. Kindly change and try again.',
    researchLength: 'Research question(s) character length is ecceded.',
    theoreticalLength: 'Theoretical and/or empirical contribution(s) character length is ecceded.',
    checkCharacterLength: 'Character length is ecceded.',
};

const aboutus = {
    aboutUs: 'Our story',
    line1: 'Hi everyone--',
    line2: "We're Nico and Shao Hang, co-founders here at Cloquia.",
    line3: "We're working on solving a big problem in research: some parts of the process can be so tedious and time-consuming that it takes away from actually doing research.",
    line4: "We're talking about things like searching the literature, writing manuscripts, connecting with other scholars, and doing outreach activities to increase research impact and visibility. These are all necessary tasks, but they're also incredibly tedious. And unfortunately, researchers are stuck using inefficient and outdated tools to accomplish them.",
    line5: "That's where Cloquia comes in.",
    line6: "Our fresh approach transforms the most tedious parts of the research process into tasks that you look forward to doing, not something you dread. Here's how we do it:",
    line7: "1. We help you build online presence fast by creating personal websites in seconds. Simply input your information on the dashboard, that's it! We take care of the rest. Your website and CV are dynamically built and updated without needing to input information multiple times and spending hours figuring out drag-and-drop website builders. Update one, and you update them all.",
    line8: "2. With Cloquia's AI Writing Assistant, you can write more persuasive research papers. It can help you identify key points and arguments that you can use to make your papers more interesting and compelling to readers.",
    line9: '3. We made literature search easy and relevant. Cloquia organizes literature by discipline, subfield, and research topic, so you can filter and refine search results to your liking. There are no algorithms, just your own choice of filters.',
    line10: "4. Keeping up with the latest in research is now more efficient than ever. Cloquia surfaces in real-time the newest scholars and latest research in your field of study so you never miss out. It's like having a personal shopper for your research.",
    line11: '5. You can say goodbye to reference managers with obsolete versions of working papers. Your Cloquia library is updated in real-time as authors update information about their scholarly works that you saved. ',
    line12: 'In short, we are building an all-in-one solution to some of the most tedious and time-consuming parts of the research cycle, so you can spend more time doing actual research!',
    line13: "We're only just beginning too - if this sounds like something you're interested in we'd love to have you on board!",
    team: 'Our Team',
    teamD: 'Our team at Cloquia is small but mighty! We are fully bootstrapped and work remotely, which allows us to be nimble and focused on creating the best product experiences.',
};

const lang = {
    ...general,
    landing,
    privacyPolicy,
    termsOfuse,
    signup,
    setup,
    dashboard,
    people,
    literature,
    addScholarlyWork,
    library,
    cv,
    personalWebsite,
    subscription,
    course,
    support,
    personalWebsiteDashbord,
    godUser,
    aiWritingAssistant,
    aboutus,
};
export default lang;
