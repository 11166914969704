import { UPDATE_EMAIL, UPDATE_USERS } from './actions';

export default function godUserReducer(
    state = {
        users: [],
        userEmail: '',
    },
    action
) {
    switch (action.type) {
        case UPDATE_EMAIL:
            return {
                ...state,
                userEmail: action.payload,
            };
        case UPDATE_USERS:
            return {
                ...state,
                users: action.payload,
            };
        default:
            return state;
    }
}
