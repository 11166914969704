import { put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
/* eslint-disable */
import {
    getUser,
    updateUser,
    deleteUser,
    removeOwnProfilePicture,
    getCheckWebsite,
    removeWebsiteLogo,
    publishUserWebsite,
    unPublishUserWebsite,
    uploadFinetuneCSV,
} from '../../services/app';
import { logout } from '../../services/auth';
import {
    GET_USER_INFO,
    GET_USER_INFO_NO_WARNING,
    CLEAR_USER_INFO,
    getUserFailed,
    getUserFailedNoWarning,
    updateUserInfo,
    CHANGE_USER_INFO,
    DELETE_USER,
    REMOVE_PROFILE_PICTURE,
    REMOVE_WEBSITE_LOGO,
    deleteUserSuccess,
    deleteUserFailed,
    PUBLISH_USER_WEBSITE,
    UNPUBLISH_USER_WEBSITE,
    UPLOAD_FINETUNE_CSV,
} from './actions';

export function* fetchUser(params) {
    const data = yield getUser();
    if (data) {
        window.localStorage.setItem('logined', true);
        yield put(updateUserInfo(data.user));
    } else if (params?.nowarn) {
        window.localStorage.setItem('logined', false);
        yield put(getUserFailedNoWarning());
    } else {
        window.localStorage.setItem('logined', false);
        message.error('Login failed!');
        yield put(getUserFailed());
    }
}

export function* logOut() {
    const success = yield logout();
    if (success) {
        window.localStorage.setItem('logined', false);
        message.success('Logged out!');
        return;
    }
    message.error('Logout failed!');
}

export function* putUser(payload) {
    const res = yield updateUser(payload);
    if (res.success) {
        res.sucCb && res.sucCb();
    } else {
        res.failCb && res.failCb();
    }
}

export function* removeProfilePicture(successCb, failCb) {
    const res = yield removeOwnProfilePicture();

    if (res) {
        console.log(res, successCb);
        successCb && successCb();
    } else {
        failCb && failCb();
    }
}

export function* removeWebsiteLogoPic(payload) {
    yield removeWebsiteLogo(payload);
}

export function* removeUser() {
    const res = yield deleteUser();
    if (res.success) {
        yield put(deleteUserSuccess());
    } else {
        yield put(deleteUserFailed({ code: 'dashboard.deleteFailed' }));
    }
}

export function* putPublishWebsite(payload) {
    const res = yield publishUserWebsite(payload);
    if (res) {
        console.log(res);
    } else {
        console.log(res);
    }
}

export function* putUnPublishWebsite() {
    const res = yield unPublishUserWebsite();
    if (res) {
        console.log(res);
    } else {
        console.log(res);
    }
}

export function* putFinetuneCSVFile(payload, successCb, failCb) {
    try {
        yield uploadFinetuneCSV(payload);
        successCb && successCb();
    } catch (e) {
        failCb && failCb();
    }
}

export function* userSagas() {
    yield takeLatest(GET_USER_INFO, fetchUser);
    yield takeLatest(GET_USER_INFO_NO_WARNING, fetchUser, { nowarn: true });
    yield takeLatest(CLEAR_USER_INFO, logOut);
    yield takeLatest(CHANGE_USER_INFO, payload => putUser(payload));
    yield takeLatest(DELETE_USER, removeUser);
    yield takeLatest(REMOVE_PROFILE_PICTURE, action =>
        removeProfilePicture(action.successCb, action.failCb)
    );
    yield takeLatest(REMOVE_WEBSITE_LOGO, payload => removeWebsiteLogoPic(payload));
    yield takeLatest(PUBLISH_USER_WEBSITE, payload => putPublishWebsite(payload));
    yield takeLatest(UNPUBLISH_USER_WEBSITE, putUnPublishWebsite);
    yield takeLatest(UPLOAD_FINETUNE_CSV, action =>
        putFinetuneCSVFile(action.payload, action.successCb, action.failCb)
    );
}
