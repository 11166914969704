import { REDUX } from '../../constants';
// actions types
export const { GET_ALL_USERS } = REDUX.APP;
export const { UPDATE_EMAIL, UPDATE_USERS } = REDUX.APP;
export const { UPDATE_OTHER_USER_EMAIL } = REDUX.GODUSER;

export function getUsers(payload) {
    return { type: GET_ALL_USERS, payload };
}

export function updateUsers(payload) {
    return { type: UPDATE_USERS, payload };
}

export function updateEmail(payload) {
    return { type: UPDATE_EMAIL, payload };
}

export function updateOtherUserEmail(userId, email, successCb, failCb) {
    return { type: UPDATE_OTHER_USER_EMAIL, payload: { userId, email }, successCb, failCb };
}

export const actions = {
    getUsers,
    updateEmail,
    updateOtherUserEmail,
};
