import { put, takeLatest, call } from 'redux-saga/effects';
import {
    UPDATE_EMAIL,
    GET_ALL_USERS,
    UPDATE_OTHER_USER_EMAIL,
    updateEmail,
    updateUsers,
} from './actions';
import { goduserLogin, getAllUsers, updateUserEmail } from '../../services/goduser';

export function* fetchAllUsers(paramsObj) {
    const data = yield call(getAllUsers, paramsObj);
    if (data) {
        console.log(data);
        yield put(updateUsers(data));
    } else {
        yield put(updateUsers([]));
    }
}

export function* sendGodUserLogin(paramsObj) {
    const data = yield call(goduserLogin, paramsObj); // service api
    if (data) {
        yield put(updateEmail(data));
    } else {
        yield put(updateEmail([]));
    }
}

export function* godUserUpdateOtherUserEmail(paramsObj, successCb, failCb) {
    const data = yield call(updateUserEmail, paramsObj); // service api
    if (data) {
        successCb();
    } else {
        failCb();
    }
}

export function* godUserSagas() {
    yield takeLatest(GET_ALL_USERS, action => fetchAllUsers(action.payload));
    yield takeLatest(UPDATE_EMAIL, action => sendGodUserLogin(action.payload));
    yield takeLatest(UPDATE_OTHER_USER_EMAIL, action =>
        godUserUpdateOtherUserEmail(action.payload, action.successCb, action.failCb)
    );
}
