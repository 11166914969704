import { REDUX } from '../../constants';
// actions types
export const { GET_LITERATURES_DATA } = REDUX.APP;
export const { UPDATE_LITERATURES_DATA } = REDUX.APP;
export const { GET_PEOPLE_DATA } = REDUX.APP;
export const { UPDATE_PEOPLE_DATA } = REDUX.APP;
export const { VALIDATE_HOST } = REDUX.APP;
export const { UPDATE_IS_CLOQUIA } = REDUX.APP;
export const { SEND_VALIDATE_EMAIL } = REDUX.APP;
export const { UPDATE_LOGIN_CONFIRM_MODAL } = REDUX.APP;
export const { UPDATE_LOGIN_FAILED_MODAL } = REDUX.APP;
export const { ADD_LITERATURE } = REDUX.APP;
export const { GET_MY_REFERENCES } = REDUX.APP;
export const { UPDATE_MY_REFERENCES } = REDUX.APP;
export const { GET_MY_LITERATURES } = REDUX.APP;
export const { UPDATE_MY_LITERATURES } = REDUX.APP;
export const { DELETE_MY_LITERATURES } = REDUX.APP;
export const { DELETE_MY_REFERENCE } = REDUX.APP;
export const { UPDATE_MY_LITERATURE_INFO, BULK_UPDATE_LITERATURE_DISPLAY_ON_CV } = REDUX.APP;
export const { UPLOAD_IMAGES_AND_FILES } = REDUX.APP;
export const { ADD_REFERENCE } = REDUX.APP;
export const {
    UPLOAD_COURSE_FILES,
    UPLOAD_RESEARCH_STATEMENT,
    UPLOAD_TEACHING_STATEMENT,
    UPLOAD_DIVERSITY_STATEMENT,
} = REDUX.APP;
export const { ADD_CV_DATA } = REDUX.APP;
export const { UPDATE_MY_CVDATA } = REDUX.APP;
export const { SELECTED_PUBLICATION_TYPE, SCHOLARLY_WORK_FORM_STEP, UPDATE_SCHOLARLY_WORK_DATA } =
    REDUX.APP;

export function selectedPublicationType(publicationType) {
    return { type: SELECTED_PUBLICATION_TYPE, payload: publicationType };
}
export const {
    SEND_UPDATE_EMAIL_LINK,
    UPDATE_EMAIL_CONFIRM_MODAL,
    UPDATE_EMAIL_FAILED_MODAL,
    EMAIL_CHANGED,
} = REDUX.APP;
export const { GET_STRIPE_PRICES, UPDATE_STRIPE_PRICES, UPDATE_SELECTED_PLAN } = REDUX.APP;
export function getLiteraturesData(params) {
    return { type: GET_LITERATURES_DATA, params };
}
export function updateLiteraturesData(value) {
    return { type: UPDATE_LITERATURES_DATA, value };
}
export function getPeopleData(params) {
    return { type: GET_PEOPLE_DATA, params };
}
export function updatePeopleData(value, showMe = false) {
    return { type: UPDATE_PEOPLE_DATA, value, showMe };
}

export function validateHost() {
    return { type: VALIDATE_HOST };
}
export function updateIsCloquia(payload) {
    return { type: UPDATE_IS_CLOQUIA, payload };
}

export function sendValidateEmail(payload) {
    return { type: SEND_VALIDATE_EMAIL, payload };
}

export function updateLoginConfirmModal(payload) {
    return { type: UPDATE_LOGIN_CONFIRM_MODAL, payload };
}

export function updateLoginFailedModal(payload) {
    return { type: UPDATE_LOGIN_FAILED_MODAL, payload };
}

export function addLiterature(payload, successCb, failCb) {
    return { type: ADD_LITERATURE, payload, successCb, failCb };
}

export function getMyReferences() {
    return { type: GET_MY_REFERENCES };
}

export function updateMyReferences(payload) {
    return { type: UPDATE_MY_REFERENCES, payload };
}

export function getMyLiteratures() {
    return { type: GET_MY_LITERATURES };
}

export function updateMyLiteratures(payload) {
    return { type: UPDATE_MY_LITERATURES, payload };
}

export function delLiterature(payload, successCb, failCb) {
    return { type: DELETE_MY_LITERATURES, payload, successCb, failCb };
}

export function delMyReference(payload, successCb, failCb) {
    return { type: DELETE_MY_REFERENCE, payload, successCb, failCb };
}

export function updateMyLiteratureInfo(payload, successCb, failCb) {
    return { type: UPDATE_MY_LITERATURE_INFO, payload, successCb, failCb };
}

export function bulkUpdateLiteratureDisplayOnCV(payload, successCb, failCb) {
    return { type: BULK_UPDATE_LITERATURE_DISPLAY_ON_CV, payload, successCb, failCb };
}

export function uploadImagesAndFiles(payload, successCb, failCb) {
    return { type: UPLOAD_IMAGES_AND_FILES, payload, successCb, failCb };
}

export function addReference(payload, successCb, failCb) {
    return { type: ADD_REFERENCE, payload, successCb, failCb };
}
export function uploadCourseFiles(payload, successCb, failCb) {
    return { type: UPLOAD_COURSE_FILES, payload, successCb, failCb };
}
export function uploadResearchStatement(payload, successCb, failCb) {
    return { type: UPLOAD_RESEARCH_STATEMENT, payload, successCb, failCb };
}
export function uploadTeachingStatement(payload, successCb, failCb) {
    return { type: UPLOAD_TEACHING_STATEMENT, payload, successCb, failCb };
}
export function uploadDiversityStatement(payload, successCb, failCb) {
    return { type: UPLOAD_DIVERSITY_STATEMENT, payload, successCb, failCb };
}
export function addCvData(payload, successCb, failCb) {
    return { type: ADD_CV_DATA, payload, successCb, failCb };
}
export function updateMyCvData(payload) {
    return { type: UPDATE_MY_CVDATA, payload };
}
export function setScholarlyWorkFormStep(payload) {
    return { type: SCHOLARLY_WORK_FORM_STEP, payload };
}
export function updateScholarlyWorkData(payload) {
    return { type: UPDATE_SCHOLARLY_WORK_DATA, payload };
}

export function sendChangeEmailConfirmationLink(payload) {
    return {
        type: SEND_UPDATE_EMAIL_LINK,
        payload,
    };
}
export function updateEmailConfirmationModal(payload) {
    return { type: UPDATE_EMAIL_CONFIRM_MODAL, payload };
}

export function updateEmailConfirmationFailedModal(payload) {
    return { type: UPDATE_EMAIL_FAILED_MODAL, payload };
}

export function emailChanged(payload) {
    return { type: EMAIL_CHANGED, payload };
}

export function getStripePrices() {
    return { type: GET_STRIPE_PRICES };
}

export function updateStripePrices(payload) {
    return { type: UPDATE_STRIPE_PRICES, payload };
}

export function updateSelectedPlan(payload) {
    return { type: UPDATE_SELECTED_PLAN, payload };
}

export const actions = {
    getLiteraturesData,
    updateLiteraturesData,
    getPeopleData,
    updatePeopleData,
    validateHost,
    updateIsCloquia,
    sendValidateEmail,
    updateLoginConfirmModal,
    updateLoginFailedModal,
    addLiterature,
    getMyReferences,
    updateMyReferences,
    getMyLiteratures,
    updateMyLiteratures,
    delLiterature,
    delMyReference,
    updateMyLiteratureInfo,
    bulkUpdateLiteratureDisplayOnCV,
    uploadImagesAndFiles,
    addReference,
    uploadCourseFiles,
    uploadResearchStatement,
    uploadTeachingStatement,
    uploadDiversityStatement,
    addCvData,
    updateMyCvData,
    selectedPublicationType,
    setScholarlyWorkFormStep,
    updateScholarlyWorkData,
    sendChangeEmailConfirmationLink,
    updateEmailConfirmationModal,
    updateEmailConfirmationFailedModal,
    emailChanged,
    getStripePrices,
    updateStripePrices,
    updateSelectedPlan,
};
