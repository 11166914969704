import {
    UPDATE_LITERATURES_DATA,
    UPDATE_PEOPLE_DATA,
    UPDATE_IS_CLOQUIA,
    UPDATE_LOGIN_CONFIRM_MODAL,
    UPDATE_LOGIN_FAILED_MODAL,
    UPDATE_MY_REFERENCES,
    UPDATE_MY_LITERATURES,
    UPDATE_MY_CVDATA,
    SELECTED_PUBLICATION_TYPE,
    SCHOLARLY_WORK_FORM_STEP,
    UPDATE_SCHOLARLY_WORK_DATA,
    UPDATE_EMAIL_CONFIRM_MODAL,
    UPDATE_EMAIL_FAILED_MODAL,
    EMAIL_CHANGED,
    UPDATE_STRIPE_PRICES,
    UPDATE_SELECTED_PLAN,
} from './actions';

export default function appReducer(
    state = {
        isCloquia: null,
        showConfirmEmailModal: false,
        failModalVisible: false,
        peopleData: [],
        literaturesData: [],
        myReferences: [],
        myLiteratures: [],
        showMe: false,
        cvData: [],
        selectedPublicationType: null,
        scholarlyWorkFormStep: 0,
        scholarlyWorkData: null,
        showUpdateEmailConfirmationModal: false,
        updateEmailConfirmationModal: false,
        emailChanged: false,
        stripePrices: null,
        selectedPlan: {
            productId: null,
            priceId: null,
            subscriptionMonths: 1,
            ai_credits: 0,
        },
    },
    action
) {
    switch (action.type) {
        case UPDATE_LITERATURES_DATA:
            return {
                ...state,
                literaturesData: action.value,
            };
        case UPDATE_PEOPLE_DATA:
            return {
                ...state,
                peopleData: action.value,
                showMe: action.showMe,
            };
        case UPDATE_IS_CLOQUIA:
            return {
                ...state,
                isCloquia: action.payload,
            };
        case UPDATE_LOGIN_CONFIRM_MODAL:
            return {
                ...state,
                showConfirmEmailModal: action.payload,
            };
        case UPDATE_LOGIN_FAILED_MODAL:
            return {
                ...state,
                failModalVisible: action.payload,
            };
        case UPDATE_MY_REFERENCES:
            return {
                ...state,
                myReferences: action.payload,
            };
        case UPDATE_MY_LITERATURES:
            return {
                ...state,
                myLiteratures: action.payload,
            };
        case UPDATE_MY_CVDATA:
            return {
                ...state,
                cvData: action.payload,
            };
        case SELECTED_PUBLICATION_TYPE:
            return {
                ...state,
                selectedPublicationType: action.payload,
            };
        case SCHOLARLY_WORK_FORM_STEP:
            return {
                ...state,
                scholarlyWorkFormStep: action.payload,
            };
        case UPDATE_SCHOLARLY_WORK_DATA:
            return {
                ...state,
                scholarlyWorkData: action.payload,
            };
        case UPDATE_EMAIL_CONFIRM_MODAL:
            return {
                ...state,
                showUpdateEmailConfirmationModal: action.payload,
            };
        case UPDATE_EMAIL_FAILED_MODAL:
            return {
                ...state,
                showUpdateEmailFailedModal: action.payload,
            };
        case EMAIL_CHANGED:
            return {
                ...state,
                emailChanged: action.payload,
            };
        case UPDATE_STRIPE_PRICES:
            return {
                ...state,
                stripePrices: action.payload,
            };
        case UPDATE_SELECTED_PLAN:
            return {
                ...state,
                selectedPlan: action.payload,
            };
        default:
            return state;
    }
}
