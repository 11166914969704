import { REDUX } from '../../constants';
// actions types
export const { GET_WEBSITE_INFO } = REDUX.WEBSITE;
export const { GET_WEBSITE_INFO_FAILED } = REDUX.WEBSITE;
export const { UPDATE_WEBSITE_INFO } = REDUX.WEBSITE;

export function getWebsiteInfo() {
    return { type: GET_WEBSITE_INFO };
}
export function getWebsiteFailed() {
    return { type: GET_WEBSITE_INFO_FAILED };
}
export function updateWebsiteInfo(value) {
    return { type: UPDATE_WEBSITE_INFO, value };
}
export const actions = {
    getWebsiteInfo,
    getWebsiteFailed,
    updateWebsiteInfo,
};
