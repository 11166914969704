import { put, takeLatest, call } from 'redux-saga/effects';
import {
    GET_PEOPLE_DATA,
    GET_LITERATURES_DATA,
    updatePeopleData,
    updateLiteraturesData,
    updateIsCloquia,
    VALIDATE_HOST,
    updateLoginFailedModal,
    SEND_VALIDATE_EMAIL,
    ADD_LITERATURE,
    GET_MY_REFERENCES,
    updateMyReferences,
    GET_MY_LITERATURES,
    updateMyLiteratures,
    DELETE_MY_LITERATURES,
    DELETE_MY_REFERENCE,
    UPDATE_MY_LITERATURE_INFO,
    UPLOAD_IMAGES_AND_FILES,
    ADD_REFERENCE,
    UPLOAD_COURSE_FILES,
    UPLOAD_RESEARCH_STATEMENT,
    UPLOAD_TEACHING_STATEMENT,
    UPLOAD_DIVERSITY_STATEMENT,
    ADD_CV_DATA,
    SEND_UPDATE_EMAIL_LINK,
    updateEmailConfirmationModal,
    updateEmailConfirmationFailedModal,
    GET_STRIPE_PRICES,
    updateStripePrices,
    BULK_UPDATE_LITERATURE_DISPLAY_ON_CV,
} from './actions';
import {
    validateHostname,
    peopleSearch,
    literatureSearch,
    postLiterature,
    postCvData,
    getOwnReferences,
    getOwnLiteratures,
    deleteLiterature,
    deleteOwnReference,
    putOwnLiterature,
    putLiteratures,
    uploadImage,
    uploadFile,
    putOwnReference,
    uploadSyllabusFile,
    uploadCourseEvaluationFile,
    uploadResearchStatementFile,
    uploadTeachingStatementFile,
    uploadDiversityStatementFile,
    getStripeProductPrices,
} from '../../services/app';
import { validateSendEmail, sendEmailConfirmation } from '../../services/auth';

export function* fetchLiteratureData(paramsObj) {
    const data = yield call(literatureSearch, paramsObj);
    if (data) {
        yield put(updateLiteraturesData(data.literatures));
    } else {
        yield put(updateLiteraturesData([]));
    }
}

export function* fetchPeopleData(paramsObj) {
    const data = yield peopleSearch(paramsObj);
    if (data) {
        yield put(updatePeopleData(data.users, paramsObj.showMe));
    } else {
        yield put(updatePeopleData([]));
    }
}

export function* validateIsCloquia() {
    const isCloquia = yield validateHostname();
    yield put(updateIsCloquia(isCloquia));
}

export function* sendMagicLinkEmail(email) {
    try {
        yield validateSendEmail(email);
    } catch (e) {
        yield put(updateLoginFailedModal(true));
    }
}

export function* sendUpdateEmailConfirmation(email) {
    try {
        const success = yield sendEmailConfirmation(email);
        if (success) {
            yield put(updateEmailConfirmationModal(true));
        }
    } catch (e) {
        yield put(updateEmailConfirmationFailedModal(true));
    }
}

export function* addLiterature(payload, successCb, failCb) {
    try {
        const result = yield call(postLiterature, payload);
        if (result) {
            successCb(result);
        } else {
            failCb();
        }
    } catch (e) {
        failCb();
    }
}

export function* getMyReferences() {
    try {
        const result = yield call(getOwnReferences);
        result.forEach(ref => {
            ref.key = ref._id;
        });
        yield put(updateMyReferences(result));
    } catch (e) {
        console.log(e);
    }
}
export function* getMyLiteratures() {
    try {
        const result = yield call(getOwnLiteratures);
        yield put(updateMyLiteratures(result));
    } catch (e) {
        console.log(e);
    }
}

export function* delLiterature(payload, successCb, failCb) {
    try {
        yield call(deleteLiterature, payload);
        successCb && successCb();
    } catch (e) {
        failCb && failCb();
    }
}

export function* addReference(payload, successCb, failCb) {
    try {
        const res = yield call(putOwnReference, payload);
        if (res.ok) {
            successCb && successCb();
        } else {
            failCb && failCb();
        }
    } catch (e) {
        console.log(e);
        failCb && failCb();
    }
}
export function* delMyReference(payload, successCb, failCb) {
    try {
        yield call(deleteOwnReference, payload);
        successCb && successCb();
    } catch (e) {
        failCb && failCb();
    }
}

export function* updateMyLiteratureInfo(payload, successCb, failCb) {
    try {
        const result = yield call(putOwnLiterature, payload);
        successCb && successCb(result);
    } catch (e) {
        failCb && failCb();
    }
}

export function* bulkUpdateLiteratureDisplayOnCV(payload, successCb, failCb) {
    try {
        const result = yield call(putLiteratures, payload);
        successCb && successCb(result);
    } catch (e) {
        failCb && failCb();
    }
}

export function* uploadImagesAndFiles(payload, successCb, failCb) {
    try {
        yield call(uploadImage, payload);
        yield call(uploadFile, payload);
        successCb && successCb();
    } catch (e) {
        console.log(e);
        failCb();
    }
}
export function* uploadCourseFiles(payload, successCb) {
    if (payload.courseSyllabus.file) {
        yield call(uploadSyllabusFile, payload);
    }
    if (payload.courseEvaluation.file) {
        yield call(uploadCourseEvaluationFile, payload);
    }
    yield call(uploadFile, payload);
    successCb && successCb();
}
export function* uploadResearchStatement(payload, successCb) {
    if (payload.researchStatement.file?.length) {
        yield call(uploadResearchStatementFile, payload);
    }
    successCb && successCb();
}
export function* uploadTeachingStatement(payload, successCb) {
    if (payload.teachingPhilosophy.file?.length) {
        yield call(uploadTeachingStatementFile, payload);
    }
    successCb && successCb();
}
export function* uploadDiversityStatement(payload, successCb) {
    if (payload.diversityStatement.file?.length) {
        yield call(uploadDiversityStatementFile, payload);
    }
    successCb && successCb();
}
export function* addCvData(payload, successCb, failCb) {
    try {
        const result = yield call(postCvData, payload);
        if (result) {
            successCb && successCb(result);
        } else {
            failCb();
        }
    } catch (e) {
        failCb && failCb();
    }
}
export function* fetchStripePrices() {
    try {
        const result = yield call(getStripeProductPrices);
        yield put(updateStripePrices(result));
    } catch (e) {
        console.log(e);
    }
}
export function* appSagas() {
    yield takeLatest(GET_LITERATURES_DATA, action => fetchLiteratureData(action.params));
    yield takeLatest(GET_PEOPLE_DATA, action => fetchPeopleData(action.params));
    yield takeLatest(VALIDATE_HOST, action => validateIsCloquia(action.payload));
    yield takeLatest(SEND_VALIDATE_EMAIL, action => sendMagicLinkEmail(action.payload));
    yield takeLatest(SEND_UPDATE_EMAIL_LINK, action => sendUpdateEmailConfirmation(action.payload));
    yield takeLatest(ADD_LITERATURE, action =>
        addLiterature(action.payload, action.successCb, action.failCb)
    );
    yield takeLatest(GET_MY_REFERENCES, action => getMyReferences(action.payload));
    yield takeLatest(GET_MY_LITERATURES, action => getMyLiteratures(action.payload));
    yield takeLatest(DELETE_MY_LITERATURES, action =>
        delLiterature(action.payload, action.successCb, action.failCb)
    );
    yield takeLatest(DELETE_MY_REFERENCE, action =>
        delMyReference(action.payload, action.successCb, action.failCb)
    );
    yield takeLatest(UPDATE_MY_LITERATURE_INFO, action =>
        updateMyLiteratureInfo(action.payload, action.successCb, action.failCb)
    );
    yield takeLatest(BULK_UPDATE_LITERATURE_DISPLAY_ON_CV, action =>
        bulkUpdateLiteratureDisplayOnCV(action.payload, action.successCb, action.failCb)
    );
    yield takeLatest(UPLOAD_IMAGES_AND_FILES, action =>
        uploadImagesAndFiles(action.payload, action.successCb, action.failCb)
    );
    yield takeLatest(ADD_REFERENCE, action =>
        addReference(action.payload, action.successCb, action.failCb)
    );
    yield takeLatest(UPLOAD_COURSE_FILES, action =>
        uploadCourseFiles(action.payload, action.successCb, action.failCb)
    );
    yield takeLatest(UPLOAD_RESEARCH_STATEMENT, action =>
        uploadResearchStatement(action.payload, action.successCb, action.failCb)
    );
    yield takeLatest(UPLOAD_TEACHING_STATEMENT, action =>
        uploadTeachingStatement(action.payload, action.successCb, action.failCb)
    );
    yield takeLatest(UPLOAD_DIVERSITY_STATEMENT, action =>
        uploadDiversityStatement(action.payload, action.successCb, action.failCb)
    );
    yield takeLatest(ADD_CV_DATA, action =>
        addCvData(action.payload, action.successCb, action.failCb)
    );
    yield takeLatest(GET_STRIPE_PRICES, action => fetchStripePrices(action.params));
}
