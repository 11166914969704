const theme = {
    primaryColor: '#1c2c4c',
    secondaryColor: '#e49c44',
    textSecondaryColor: '#8c9ca8',
    borderColor: 'rgba(217, 217, 217, 0.4)',
    inputBgColor: '#F0F0F0',
    inputPlaceholderColor: '#75726E',
    dashboardAsidWidth: '360px',
    dashboardMainWidth: '743px',
    topbarHight: '80px',
    dashboardSectionPadding: '20px',
    headFontFamily: 'LibreBaskerville',
    headFontFamilyBold: 'LibreBaskervilleBold',
    headFontColor: '#28241f',
    secondaryHeadingColor: '#387099',
    sidebarsColor: '#FAFAFA',
    bodyBGColor: '#FAFAFA',
    personalWebsiteBodyBGColor: '#FFFFFF',
    secondaryFontFamily: 'InterRegular',
    cloquiaSecondaryColor: '#ffcf24',
};

export default theme;
