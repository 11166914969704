import React, { Suspense, useEffect, useState } from 'react';
import { useStore, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useLocation } from 'react-router';
import './App.less';
import './assets/fonts/FontsFree-Net-AvenirLTStd-Book.ttf';
import './assets/fonts/FontsFree-Net-AvenirLTStd-Heavy.ttf';
import './assets/fonts/LibreBaskerville-Regular.ttf';
import { ThemeProvider } from 'styled-components';
import { renderRoutes } from 'react-router-config'; // generate all routes
import theme from './theme';
import './assets/fonts/Inter-Regular.ttf';
import { routes, personalWebsiteRoutes } from './router/router';
import { validateHost } from './redux/app/actions';
import { getUserInfo, getUserInfoNoWarning } from './redux/user/actions';

function App(props) {
    const store = useStore();
    const userState = useSelector(state => state.user);
    const isCloquia = useSelector(state => state.app.isCloquia);
    const user = userState?.userInfo;
    const loginFailed = userState?.loginFailed;
    const [firstTimeRender, setFirstTimeRender] = useState(true);
    const [dynamicRoutes, setDynamicRoutes] = useState(null);
    const location = useLocation();

    useEffect(() => {
        store.dispatch(validateHost());
    }, [store]);

    useEffect(() => {
        if (isCloquia === true) {
            setDynamicRoutes(routes);
        } else if (isCloquia === false) {
            setDynamicRoutes(personalWebsiteRoutes);
        }
    }, [isCloquia]);

    useEffect(() => {
        if (dynamicRoutes && isCloquia) {
            if (
                location.pathname === '/' ||
                location.pathname === '/login' ||
                location.pathname === '/privacy-policy' ||
                location.pathname === '/terms-of-use' ||
                location.pathname === '/aboutus'
            ) {
                setFirstTimeRender(false);
                store.dispatch(getUserInfoNoWarning());
            } else {
                setFirstTimeRender(false);
                store.dispatch(getUserInfo());
            }
        }
        /* eslint-disable-next-line */
    }, [store, dynamicRoutes, isCloquia]);

    useEffect(() => {
        if (
            ['/', '/privacy-policy', '/terms-of-use', '/aboutus'].includes(location.pathname) ===
                true &&
            !user
        ) {
            return;
        }

        if (!firstTimeRender && dynamicRoutes && isCloquia && !userState.loading) {
            if (!user) {
                props.history.replace('/login');
            } else if (!loginFailed && location.pathname === '/') {
                props.history.replace('/dashboard/people');
            } else if (!loginFailed && user && location.pathname === '/login') {
                props.history.replace('/dashboard/people');
            }
        }

        /* eslint-disable-next-line */
    }, [loginFailed, user, userState.loading, firstTimeRender, dynamicRoutes, isCloquia]);
    return (
        <div
            className="App"
            style={{
                backgroundColor: isCloquia ? theme.bodyBGColor : theme.personalWebsiteBodyBGColor,
            }}
        >
            <ThemeProvider theme={theme}>
                {/* routes */}
                <Suspense fallback={<div>loading</div>}>
                    {dynamicRoutes && renderRoutes(dynamicRoutes)}
                </Suspense>
            </ThemeProvider>
        </div>
    );
}

export default withRouter(App);
