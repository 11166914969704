export const REDUX = {
    USER: {
        GET_USER_INFO: 'getUserInfo',
        GET_USER_INFO_NO_WARNING: 'getUserInfoNoWarning',
        GET_USER_FAILED: 'getUserFailed',
        GET_USER_FAILED_NO_WARNING: 'getUserFailedNoWarning',
        UPDATE_USER_INFO: 'updateUserInfo',
        CLEAR_USER_INFO: 'clearUserInfo',
        CHANGE_USER_INFO: 'changeUserInfo',
        DELETE_USER: 'deleteUser',
        DELETE_USER_SUCCESS: 'deleteUserSuccess',
        DELETE_USER_FAILED: 'deleteUserFailed',
        SET_LOADING: 'setLoading',
    },
    WEBSITE: {
        GET_WEBSITE_INFO: 'getWebsiteInfo',
        GET_WEBSITE_INFO_FAILED: 'getWebsiteInfoFailed',
        UPDATE_WEBSITE_INFO: 'updateWebsiteInfo',
    },
    APP: {
        GET_LITERATURES_DATA: 'getLiteratures',
        UPDATE_LITERATURES_DATA: 'updateLiteratures',
        GET_PEOPLE_DATA: 'getPeopleData',
        UPDATE_PEOPLE_DATA: 'updatePeopleData',
        VALIDATE_HOST: 'validateHost',
        UPDATE_IS_CLOQUIA: 'updateIsCloquia',
        SEND_VALIDATE_EMAIL: 'sendValidateEmail',
        UPDATE_LOGIN_CONFIRM_MODAL: 'updateLoginConfirmModal',
        UPDATE_LOGIN_FAILED_MODAL: 'updateLoginFailedModal',
        ADD_LITERATURE: 'addLiterature',
        GET_MY_REFERENCES: 'getMyReferences',
        ADD_REFERENCE: 'addReference',
        UPDATE_MY_REFERENCES: 'updateMyReferences',
        GET_MY_LITERATURES: 'getMyLiteratures',
        UPDATE_MY_LITERATURES: 'updateMyLiteratures',
        DELETE_MY_LITERATURES: 'deleteMyLiterature',
        DELETE_MY_REFERENCE: 'deleteMyReference',
        UPDATE_MY_LITERATURE_INFO: 'updateMyLiteratureInfo',
        BULK_UPDATE_LITERATURE_DISPLAY_ON_CV: 'bulkUpdateLiteratureDisplayOnCV',
        UPLOAD_IMAGES_AND_FILES: 'uploadImagesAndFiles',
        UPLOAD_COURSE_FILES: 'uploadCourseFiles',
        UPLOAD_RESEARCH_STATEMENT: 'uploadResearchStatement',
        UPLOAD_TEACHING_STATEMENT: 'uploadTeachingStatement',
        UPLOAD_DIVERSITY_STATEMENT: 'uploadDiversityStatement',
        ADD_CV_DATA: 'addCvData',
        UPDATE_MY_CVDATA: 'updateMyCvData',
        GET_ALL_USERS: 'getAllUsers',
        UPDATE_EMAIL: 'updateEmail',
        UPDATE_USERS: 'updateUsers',
        SELECTED_PUBLICATION_TYPE: 'selectedPublicationType',
        SCHOLARLY_WORK_FORM_STEP: 'setScholarlyWorkFormStep',
        UPDATE_SCHOLARLY_WORK_DATA: 'updateScholarlyWorkData',
        SEND_UPDATE_EMAIL_LINK: 'sendChangeEmailConfirmationLink',
        UPDATE_EMAIL_CONFIRM_MODAL: 'updateEmailConfirmationModal',
        UPDATE_EMAIL_FAILED_MODAL: 'updateEmailConfirmationFailedModal',
        EMAIL_CHANGED: 'emailChanged',
        GET_STRIPE_PRICES: 'getStripePrices',
        UPDATE_STRIPE_PRICES: 'updateStripePrices',
        SELECTED_PLAN: 'selectedPlan',
        UPDATE_SELECTED_PLAN: 'updateSelectedPlan',
        REMOVE_PROFILE_PICTURE: 'removeProfilePicture',
        REMOVE_WEBSITE_LOGO: 'removewebsiteLogo',
        PUBLISH_USER_WEBSITE: 'publishWebsite',
        UNPUBLISH_USER_WEBSITE: 'unpublishWebsite',
        UPLOAD_FINETUNE_CSV: 'uploadFinetuneCsv',
        GET_FINETUNE_MODELS: 'getFineTuneModels',
    },
    GODUSER: {
        UPDATE_OTHER_USER_EMAIL: 'updateOtherUserEmail',
    },
};

export const REGS = {
    /* eslint-disable */
    PERSONNAME:
        /^(?!.*?\.\.)[\-a-zA-Z\sáàâäãèéêëîïíôœùûüÿçñóõúÁÀÂÄÃÈÉÊËÎÏÍÔŒÙÛÜŸÇÑÓÕÚ\u4e00-\u9eff\.]+$/,
    EMAIL: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/,
    USERNAME: /^[-\$\.\+\*\(\)a-zA-Z0-9\_\sàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\u4e00-\u9eff]+$/,
    POSTALCODE: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][0-9][ABCEGHJ-NPRSTV-Z][0-9]$/,
    ZIPCODE: /^[0-9]{5}(?:-[0-9]{4})?$/,
    COLORCODE: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
    WEBSITE: /^[a-z0-9-.]+$/,
    /* eslint-enable */
};

export const API_ADDR = {
    DEV: 'http://localhost:8080',
};

export const PEOPLE_FILTER = {
    ANYWHERE: 'anywhere',
    IN_TITLE: 'inTitle',
};

export const SCHOLAR_COMP_TYPE = {
    RESERCH: 'research',
    TEACHING: 'teaching',
    CV: 'cv',
    OTHER_WORKS: 'otherWorks',
};

export const SEARCH_CONFIG = {
    LITERATURE: {
        PATH: '/dashboard/literature',
        ENDPOINT: '/api-admin/user/me/literatures/search',
    },
    PEOPLE: {
        PATH: '/dashboard/people',
        ENDPOINT: '/api-admin/users/search',
    },
};

export const SEARCH_MODE = {
    LITERATURE: 'literature',
    PEOPLE: 'PEOPLE',
};

export const UPLOAD_TYPE = {
    SYLLABUS: 'syllabus',
    COURSE_EVALUATION: 'courseEvaluation',
    COURSE_MEDIA: 'courseMedia',
};

export const PERSONAL_WEBSITE_ROUTES = {
    RESEARCH: '/research',
    TEACHING: '/teaching',
};

export const ENV = {
    LOCAL: 'local',
    DEVELOPMENT: 'development',
    STAGING: 'staging',
    PRODUCTION: 'production',
};
export const URLS = {
    APP_LOCAL: 'http://localhost:8081',
    WEB_LOCAL: 'http://localhost:8082',
    DEVELOPMENT: 'https://cloquiadev.com',
    STAGING: 'https://cloquiastaging.com',
    PRODUCTION: 'https://cloquia.com/',
};

export const WEBSITE_SUFFIX = {
    WEB_LOCAL: '.localhost:8082',
    DEVELOPMENT: '.cloquiadev.com',
    STAGING: '.cloquiastaging.com',
    PRODUCTION: '.cloquia.com',
};

export const libraryTabType = {
    myReferences: 'myReferences',
    myScholaryWork: 'myWork',
};

export const literatureStatus = {
    draft: 'draft',
    published: 'published',
};

export const category = {
    customCategory: 'customCategory',
    other: 'other',
};

export const action = {
    edit: 'edit',
    hideFromCv: 'hideFromCv',
    displayOnCv: 'displayOnCv',
    delete: 'delete',
};

export const PLANS = {
    basic: 'basic',
    personal: 'personal',
    monthly: 'monthly',
    yearly: 'yearly',
    biennial: 'biennial',
    basic24Months: 'basic24Months',
    personal24Months: 'personal24Months',
};

export const support = {
    supportEmail: 'nico@cloquia.com',
};

export const APPLY_HEADER = {
    HOMEPAGE: 'homePageOnly',
    ALLPAGES: 'allPages',
};

export const THEMES = {
    CLASSIC: 'classic',
    SLIDEBARCLASSIC: 'siderbar_classic',
};

export const FONTS = {
    ARIAL: 'Arial',
    MONTSERRAT: 'Montserrat',
    LIBRE: 'Libre Baskerville',
};

export const AI_CREDITS = {
    BASIC_MONTHLY: 50,
    PERSONAL_MONTHLY: 150,
    PREMIUM_MONTHLY: 500,
};
