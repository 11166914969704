export const validateSendEmail = async emailInput => {
    const isSuccess = await fetch('/api-auth/magiclogin', {
        credentials: 'include',
        method: 'post',
        cache: 'no-cache',
        body: JSON.stringify({ destination: emailInput }),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(res => res.json())
        .then(json => json.success);
    return isSuccess;
};
export const sendEmailConfirmation = async emailInput => {
    const isSuccess = await fetch('/api-auth/email-confirmation', {
        credentials: 'include',
        method: 'post',
        cache: 'no-cache',
        body: JSON.stringify({ destination: emailInput }),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(res => res.json())
        .then(json => json.success);
    return isSuccess;
};
export const logout = async () => {
    const success = fetch('/api-admin/logout', {
        credentials: 'include',
        methode: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(res => {
        if (res.ok) {
            return true;
        }
        return false;
    });
    return success;
};
