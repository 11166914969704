export const goduserLogin = async emailInput => {
    console.log('emailInput');
    console.log(emailInput);
    const result = await fetch('/api-admin/goduser/loginas', {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ email: emailInput }),
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(res => {
        if (res.ok) {
            window.location.href = '/dashboard/people';
        }
        return res.json();
    });
    return result;
};

export const getAllUsers = async () => {
    const result = await fetch('/api-admin/goduser/users/emails', {
        credentials: 'include',
        method: 'GET',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(res => {
        if (res.ok) return res.json();
        return null;
    });
    return result;
};

export const updateUserEmail = async ({ userId, email }) => {
    const result = await fetch(`/api-admin/goduser/user/${userId}/email`, {
        credentials: 'include',
        method: 'PUT',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
    }).then(res => {
        if (res.ok) return res.json();
        return null;
    });
    return result;
};
