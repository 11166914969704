import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { fork, all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import userReducer from './user/reducers';
import personalWebsiteReducer from './personalWebsite/reducers';
import appReducer from './app/reducers';
import { userSagas } from './user/sagas';
import { personalWebsiteSagas } from './personalWebsite/sagas';
import { appSagas } from './app/sagas';
import { godUserSagas } from './goduser/sagas';
import godUserReducer from './goduser/reducers';
import reduxDevTool from '../utils/reduxDevTool';
import { ENV } from '../constants';

const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
    user: userReducer,
    personalWebsite: personalWebsiteReducer,
    app: appReducer,
    godUser: godUserReducer,
});
let storeOption = null;
if (process.env.REACT_APP_ENV === ENV.LOCAL && window.__REDUX_DEVTOOLS_EXTENSION__) {
    storeOption = createStore(
        reducers,
        compose(
            applyMiddleware(sagaMiddleware),
            reduxDevTool() // enable reduxDevTool
        )
    );
} else {
    storeOption = createStore(reducers, compose(applyMiddleware(sagaMiddleware)));
}
function* rootSaga() {
    yield all([fork(userSagas), fork(personalWebsiteSagas), fork(appSagas), fork(godUserSagas)]);
}
const store = storeOption;
sagaMiddleware.run(rootSaga);

export default store;
