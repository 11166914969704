import { GET_WEBSITE_INFO_FAILED, UPDATE_WEBSITE_INFO } from './actions';

export default function websiteReducer(
    state = {
        userInfo: null,
        style: null,
    },
    action
) {
    switch (action.type) {
        case UPDATE_WEBSITE_INFO:
            return {
                ...state,
                userInfo: action.value,
            };
        case GET_WEBSITE_INFO_FAILED:
            return {
                ...state,
                userInfo: null,
            };
        default:
            return state;
    }
}
