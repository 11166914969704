import {
    UPDATE_USER_INFO,
    CLEAR_USER_INFO,
    GET_USER_FAILED,
    GET_USER_FAILED_NO_WARNING,
    UPDATE_EMAIL,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
    SET_LOADING,
} from './actions';

export default function userReducer(
    state = {
        userInfo: null,
        loginFailed: false,
        loading: true,
        users: [],
        userEmail: '',
        deleted: false,
        error: null,
    },
    action
) {
    switch (action.type) {
        case UPDATE_USER_INFO:
            return {
                ...state,
                userInfo: action.value,
                loginFailed: false,
                loading: false,
            };
        case GET_USER_FAILED:
            return {
                ...state,
                userInfo: null,
                loginFailed: true,
                loading: false,
            };
        case GET_USER_FAILED_NO_WARNING:
            return {
                ...state,
                userInfo: null,
                loading: false,
            };
        case CLEAR_USER_INFO:
            return {
                ...state,
                userInfo: null,
                loginFailed: false,
                loading: false,
            };
        case UPDATE_EMAIL:
            return {
                ...state,
                userEmail: action.value, // add here
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                userInfo: null,
                loginFailed: false,
                loading: false,
                deleted: true,
            };
        case DELETE_USER_FAILED:
            return {
                ...state,
                loading: false,
                deleted: false,
                error: action.payload,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
}
