import { isEmpty } from 'lodash';

// Validate if it is cloquia app
export const validateHostname = async () => {
    const validation = await fetch('/api-public/host', {
        credentials: 'include',
        method: 'get',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'website-domain': window.location.host,
        },
    });
    if (validation.ok) {
        const res = await validation.json();
        return res.isCloquia;
    }
};

export const validateEmail = async email => {
    const validate = fetch(`/api-admin/validate/${email}`, {
        credentials: 'include',
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        return null;
    });
    return validate;
};

export const getUser = async () => {
    const data = fetch('/api-admin/user/me', {
        credentials: 'include',
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        return null;
    });
    return data;
};

export const updateUser = async ({ payload }) => {
    const res = await fetch('/api-admin/user/me', {
        credentials: 'include',
        method: 'put',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload.body),
    });

    return {
        success: !!res.ok,
        sucCb: payload.success,
        failCb: payload.fail,
    };
};

export const deleteUser = async () => {
    const res = await fetch('/api-admin/user/me', {
        credentials: 'include',
        method: 'delete',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return {
        success: !!res.ok,
    };
};

export const literatureSearch = async paramsObj => {
    let query = '';

    paramsObj &&
        Object.keys(paramsObj).forEach(key => {
            if (paramsObj[key]) {
                if (!query.length) {
                    query += `?${key}=${paramsObj[key]}`;
                } else {
                    query += `&${key}=${paramsObj[key]}`;
                }
            }
        });
    const data = await fetch(`/api-admin/user/me/literatures/search${query}`, {
        credentials: 'include',
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(async res => {
        if (res.ok) {
            return res.json();
        }
        return null;
    });

    return data;
};

export const peopleSearch = async paramsObj => {
    let query = '';

    paramsObj &&
        Object.keys(paramsObj).forEach(key => {
            if (paramsObj[key]) {
                if (!query.length) {
                    query += `?${key}=${paramsObj[key]}`;
                } else {
                    query += `&${key}=${paramsObj[key]}`;
                }
            }
        });
    const data = await fetch(`/api-admin/users/search${query}`, {
        credentials: 'include',
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(async res => {
        if (res.ok) {
            return res.json();
        }
        return null;
    });

    return data;
};

export const postLiterature = async payload => {
    const res = await fetch('/api-admin/literature', {
        credentials: 'include',
        method: 'post',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (res.ok) {
        return res.json();
    }
    return null;
};

export const getOwnReferences = async () => {
    const res = await fetch('/api-admin/user/me/references');

    if (res.ok) {
        const data = await res.json();
        return data.references;
    }
    return null;
};
export const putOwnReference = async id => {
    const res = await fetch(`/api-admin/user/me/literature/${id}`, {
        credentials: 'include',
        method: 'put',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res;
};

export const getOwnLiteratures = async () => {
    const res = await fetch('/api-admin/user/me/literatures');

    if (res.ok) {
        const data = await res.json();
        return data.literatures;
    }
    return null;
};

export const deleteLiterature = async id => {
    const res = await fetch(`/api-admin/literature/${id}/archive`, {
        credentials: 'include',
        method: 'put',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res;
};

export const deleteOwnReference = async id => {
    const res = await fetch(`/api-admin/user/me/reference/${id}/archive`, {
        credentials: 'include',
        method: 'put',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res;
};

export const putOwnLiterature = async scholarlyWork => {
    const res = await fetch(`/api-admin/literature/${scholarlyWork._id}`, {
        credentials: 'include',
        method: 'put',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(scholarlyWork),
    });

    if (res.ok) {
        return res.json();
    }
};

export const putLiteratures = async data => {
    const res = await fetch(`/api-admin/literatures`, {
        credentials: 'include',
        method: 'put',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (res.ok) {
        return res.json();
    }
};

export const uploadImage = async ({ _id, imageAttachment }) => {
    if (!isEmpty(imageAttachment)) {
        const images = imageAttachment.filter(i => i.uid);
        if (!isEmpty(images)) {
            const formData = new FormData();
            images.forEach(file => {
                formData.append('pictures', file.originFileObj);
            });
            const res = await fetch(`/api-admin/literature/${_id}/image`, {
                credentials: 'include',
                method: 'PUT',
                cache: 'no-cache',
                body: formData,
            });
            return res;
        }
    }
};

export const removeOwnProfilePicture = async () => {
    const res = await fetch(`/api-admin/user/me/picture`, {
        credentials: 'include',
        method: 'delete',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (res.ok) {
        return res.json();
    }
};

export const removeWebsiteLogo = async type => {
    const res = await fetch(`/api-admin/user/me/sitelogo/${type.payload}`, {
        credentials: 'include',
        method: 'delete',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (res.ok) {
        return res.json();
    }
};

export const uploadFile = async ({ _id, fileAttachment, additionalAttachmentsFiles }) => {
    const formData = new FormData();
    if (!isEmpty(fileAttachment)) {
        const files = fileAttachment.filter(file => !file._id);
        if (files.length) {
            files.forEach(file => {
                formData.append('files', file.originFileObj);
            });
        }
    }
    if (!isEmpty(additionalAttachmentsFiles)) {
        const additionalFiles = additionalAttachmentsFiles.filter(file => {
            return file.path === undefined;
        });
        if (additionalFiles.length > 0) {
            additionalFiles.forEach((file, index) => {
                if (file.uid !== undefined) {
                    if (file.originFileObj !== undefined) {
                        file.originFileObj.uid = file.uid;
                        formData.append('additionalAttachmentsFiles', file.originFileObj);
                    } else {
                        formData.append('additionalAttachmentsFiles', file);
                    }
                    formData.append('additionalFilesUid', file.uid);
                }
            });
        }
    }
    if (!isEmpty(fileAttachment) || !isEmpty(additionalAttachmentsFiles)) {
        const res = await fetch(`/api-admin/literature/${_id}/file`, {
            credentials: 'include',
            method: 'PUT',
            cache: 'no-cache',
            body: formData,
        });
        return res;
    }
};

export const uploadSyllabusFile = async ({ _id, courseSyllabus }) => {
    const files = courseSyllabus.file.filter(file => !file._id);
    const formData = new FormData();
    files.forEach(file => {
        formData.append('files', file.originFileObj);
    });
    if (files.length === 0) {
        return;
    }

    const res = await fetch(`/api-admin/literature/${_id}/courseSyllabus`, {
        credentials: 'include',
        method: 'PUT',
        cache: 'no-cache',
        headers: {
            url: courseSyllabus.url !== undefined ? courseSyllabus.url : null,
        },
        body: formData,
    });
    return res;
};

export const uploadCourseEvaluationFile = async ({ _id, courseEvaluation }) => {
    const files = courseEvaluation.file.filter(file => !file._id);
    const formData = new FormData();
    files.forEach(file => {
        formData.append('files', file.originFileObj);
    });
    if (files.length === 0) {
        return;
    }
    const res = await fetch(`/api-admin/literature/${_id}/courseEvaluation`, {
        credentials: 'include',
        method: 'PUT',
        cache: 'no-cache',
        headers: {
            url: courseEvaluation.url !== undefined ? courseEvaluation.url : null,
        },
        body: formData,
    });
    return res;
};

export const uploadCourseMediaFile = async ({ _id, courseMedia }) => {
    if (!isEmpty(courseMedia)) {
        const files = courseMedia.filter(file => file.file === null || !file.file?._id);
        if (files.length > 0) {
            const formData = new FormData();
            const data = [];
            files.forEach(file => {
                data.push({
                    url: file.url,
                    file: file.file !== null ? file.file[0].name : '',
                });
                formData.append('files', file.file !== null ? file.file[0].originFileObj : {});
            });
            const res = await fetch(`/api-admin/literature/${_id}/courseMedia`, {
                credentials: 'include',
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    files: JSON.stringify(data),
                },
                body: formData,
            });
            return res;
        }
    }
};

export const uploadResearchStatementFile = async ({ _id, researchStatement }) => {
    const files = researchStatement.file.filter(file => !file._id);
    const formData = new FormData();
    files.forEach(file => {
        formData.append('files', file.originFileObj);
    });
    const res = await fetch(`/api-admin/literature/${_id}/researchStatement`, {
        credentials: 'include',
        method: 'PUT',
        cache: 'no-cache',
        headers: {
            url: researchStatement.url,
        },
        body: formData,
    });
    return res;
};

export const uploadTeachingStatementFile = async ({ _id, teachingPhilosophy }) => {
    const files = teachingPhilosophy.file.filter(file => !file._id);
    const formData = new FormData();
    files.forEach(file => {
        formData.append('files', file.originFileObj);
    });
    const res = await fetch(`/api-admin/literature/${_id}/teachingPhilosophy`, {
        credentials: 'include',
        method: 'PUT',
        cache: 'no-cache',
        headers: {
            url: teachingPhilosophy.url,
        },
        body: formData,
    });
    return res;
};

export const uploadDiversityStatementFile = async ({ _id, diversityStatement }) => {
    const files = diversityStatement.file.filter(file => !file._id);
    const formData = new FormData();
    files.forEach(file => {
        formData.append('files', file.originFileObj);
    });
    const res = await fetch(`/api-admin/literature/${_id}/diversityStatement`, {
        credentials: 'include',
        method: 'PUT',
        cache: 'no-cache',
        headers: {
            url: diversityStatement.url,
        },
        body: formData,
    });
    return res;
};
export const postCvData = async payload => {
    const res = await fetch('/api-admin/user/me/cv', {
        credentials: 'include',
        method: 'put',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (res.ok) {
        return res.json();
    }
    return null;
};

export const getStripeProductPrices = async () => {
    const res = await fetch('/api-admin/stripeproducts');

    if (res.ok) {
        const data = await res.json();
        return data.products;
    }
    return null;
};

export const createSubscription = async payload => {
    const res = await fetch('/api-admin/create-subscription', {
        credentials: 'include',
        method: 'post',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (res.ok) {
        return res.json();
    }
    return null;
};

export const saveSubscriptionData = async payload => {
    const res = await fetch('/api-admin/store-subscription', {
        credentials: 'include',
        method: 'post',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (res.ok) {
        return res.json();
    }
    return null;
};

export const createStripeProducts = async payload => {
    const res = await fetch('/api-admin/stripeproducts', {
        credentials: 'include',
        method: 'post',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (res.ok) {
        return res.json();
    }
    return null;
};

export const getStripePaymentMethodDetails = async paymentMethod => {
    const res = await fetch('/api-admin/paymentMethod/' + paymentMethod, {
        method: 'get',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (res.ok) {
        return res.json();
    }
    return null;
};

export const cancelSubscription = async payload => {
    const res = await fetch('/api-admin/cancel-subscription', {
        credentials: 'include',
        method: 'post',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (res.ok) {
        return res.json();
    }
    return null;
};

export const getCheckWebsite = async site => {
    const res = await fetch(`/api-admin/user/me/checkexistingsite/${site}`, {
        method: 'get',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(res => {
        if (res.ok) {
            return true;
        }
        return false;
    });
    return res;
};

export const publishUserWebsite = async site => {
    const res = await fetch(`/api-admin/user/me/publishsite/${site.payload}`, {
        credentials: 'include',
        method: 'put',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (res.ok) {
        return res.json();
    }
};

export const unPublishUserWebsite = async () => {
    const res = await fetch('/api-admin/user/me/unpublishsite', {
        credentials: 'include',
        method: 'put',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (res.ok) {
        return res.json();
    }
};

export const uploadFinetuneCSV = async payload => {
    console.log(payload);
    const formData = new FormData();
    payload.fileAttachment.forEach(file => {
        formData.append('files', file.originFileObj);
    });
    const res = await fetch(`/api-admin/openai/uploadFineTuneCSVFile/${payload.customModel}`, {
        method: 'post',
        cache: 'no-cache',
        body: formData,
    });
    return res;
};
