import { put, takeEvery } from 'redux-saga/effects';
import { GET_WEBSITE_INFO, getWebsiteFailed, updateWebsiteInfo } from './actions';

export function* fetchWebsiteInfo() {
    const data = yield fetch('/api-public/user', {
        credentials: 'include',
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'website-domain': window.location.host,
        },
    }).then(async res => {
        if (res.ok) {
            return res.json();
        }
        return null;
    });
    if (data) {
        yield put(updateWebsiteInfo(data.userInfo));
    } else {
        yield put(getWebsiteFailed());
    }
}

export function* personalWebsiteSagas() {
    yield takeEvery(GET_WEBSITE_INFO, fetchWebsiteInfo);
}
